import styled from "styled-components";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import PublicWrapper from "./PublicWrapper";
import Body from "./Body";
import { Sidebar } from "../components/Sidebar";
import { getAllUnreadMessagesUser } from "../redux/actions/messageActions";
import {
  getAllPaymentMethods,
  getAllInvoices,
  checkSubscriptionStatus,
  getComingInvoice,
  checkTransactionSubscriptionStatus,
  getTransactionComingInvoice,
  fetchCardDetails,
  fetchSepaDetails,
} from "../redux/actions/stripeActions";
import Loading from "../components/Loading";
import Navbar from "../components/nav-bar";

interface Props {
  children: React.ReactNode;
}

const Wrapper: FC<Props> = ({ children }) => {
  const { user, isAuthenticated, loading } = useAppSelector(
    (state) => state.auth,
  );
  const dispatch = useAppDispatch();
  const { subscription, transactionSubscription } = useAppSelector(
    (state) => state.apiUsage.usage,
  );
  const { subscriptionStatus, transactionSubscriptionStatus } = useAppSelector(
    (state) => state.portal,
  );
  const userId = user?.user_id;

  useEffect(() => {
    if (userId) {
      window.gtag("event", "conversion", {
        send_to: "AW-368719303/xQKoCKeW8boDEMfr6K8B",
      });

      // Don't call getUsage here as it's already called by loadUser
      // Only fetch these items if we have a valid userId
      dispatch(getAllPaymentMethods());
      dispatch(getAllInvoices());
      dispatch(fetchCardDetails());
      dispatch(fetchSepaDetails());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (subscription && subscriptionStatus !== "active") {
      dispatch(checkSubscriptionStatus());
    }
  }, [dispatch, subscription, subscriptionStatus]);

  useEffect(() => {
    if (subscriptionStatus === "active") {
      dispatch(getComingInvoice());
    }
  }, [dispatch, subscriptionStatus]);

  //Transaction

  useEffect(() => {
    if (transactionSubscription && transactionSubscriptionStatus !== "active") {
      dispatch(checkTransactionSubscriptionStatus());
    }
  }, [dispatch, transactionSubscription, transactionSubscriptionStatus]);

  useEffect(() => {
    if (transactionSubscriptionStatus === "active") {
      dispatch(getTransactionComingInvoice());
    }
  }, [dispatch, transactionSubscriptionStatus]);

  return loading ? (
    <Loading />
  ) : isAuthenticated ? (
    <Container>
      <Navbar />
      <Main>
        <Sidebar />
        <MainContainer>
          <Body>{children}</Body>
        </MainContainer>
      </Main>
    </Container>
  ) : (
    <PublicWrapper>{children}</PublicWrapper>
  );
};

export default Wrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
`;

const Main = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
