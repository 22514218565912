import { FC } from "react";
import Text from "../../../../../components/Text";
import Subtitle from "../../../../../components/Subtitle";
import { FaHome, FaEuroSign, FaCheck } from "react-icons/fa";
import { ListingPriceResult } from "../types";

type Props = {
  property: ListingPriceResult;
  buildingPhoto?: string;
  map?: string;
};

export const ResultSummary: FC<Props> = ({ property }) => {
  const formatPrice = (price: number) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(price));
  };

  const desc = [
    {
      icon: <FaEuroSign size={24} />,
      title: "Geschatte waarde",
      result: formatPrice(property?.price_estimation || 0),
    },
    {
      icon: <FaHome size={24} />,
      title: "Bouwjaar",
      result: property?.build_year || "-",
    },
  ];

  return (
    <div className="bg-white shadow-[0px_2px_8px_0px_#00000026] rounded-md md:flex md:flex-wrap justify-between p-4 md:p-6 absolute lg:top-[320px] lg:left-[250px] lg:w-[496px] md:top-[200px] md:left-[110px] md:w-[409px] top-[200px] left-[40px] w-[300px] grid grid-cols-2 gap-2">
      {desc.map(({ icon, title, result }, index) => (
        <div key={index} className="flex flex-col items-center gap-2">
          <div className="text-primary">{icon}</div>
          <Text className="">{title}</Text>
          <Subtitle className="flex text-base md:text-xl">{result}</Subtitle>
        </div>
      ))}
    </div>
  );
};

export default ResultSummary;
