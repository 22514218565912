import { ChangeEvent, useState, useEffect } from "react";
import Button from "../../components/Button";
import Api from "../../components/ApiCard/Api";
import Search from "../../components/Search";
import { CiSearch } from "react-icons/ci";
import DashboardContainer from "../Dashboard/components/Container";
import Header from "../Dashboard/components/Header";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectAllowedApis } from "../../redux/features/apiUsageSlice";
import { getUsage } from "../../redux/actions/apiUsage";

const categorys = [
  "Alle producten",
  "Verduurzamen",
  "Taxeren",
  "Foto's",
  "Woningdata",
  "Verzekeren",
  "Beleggen",
];

function Products() {
  const [active, setActive] = useState("Alle producten");
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const allowedApis = useAppSelector(selectAllowedApis);

  useEffect(() => {
    // Fetch usage data when component mounts
    dispatch(getUsage());
  }, [dispatch]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // Filter products based on allowed APIs
  const filterProducts = (products: any[]) => {
    return products.filter(
      (product) =>
        allowedApis.includes(product.name) &&
        (active === "Alle producten" || product.category === active) &&
        (search === "" ||
          product.name.toLowerCase().includes(search.toLowerCase())),
    );
  };

  return (
    <>
      <DashboardContainer pageTitle="Producten - Altum AI">
        <Header
          title="Woningdata producten"
          subtitle="Integreer woningdata, verduurzamingadvies en woningkenmerken met de
          woningdata producten van Altum AI"
        />

        <div className="flex gap-4 flex-col lg:flex-row lg:w-full">
          <div>
            <div className="w-[326px] md:w-full lg:w-[204px]">
              <Search onChange={handleSearch} icon={CiSearch} />
            </div>
            <div className="flex md:flex-row lg:flex-col items-start overflow-x-auto space-x-0 w-full overflow-y-hidden ">
              {categorys.map((category) => (
                <Button
                  key={category}
                  className={`${
                    active === category ? "bg-gray-light/10" : ""
                  } text-gray-dark mt-2 p-0 h-[43px]`}
                  size="md"
                  onClick={() => setActive(category)}
                >
                  {category}
                </Button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Api activeCategory={active} search={search} />
          </div>
        </div>
      </DashboardContainer>
    </>
  );
}

export default Products;
