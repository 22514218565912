import React, { ReactNode } from "react";
import ApiFormContainer from "../../components/ApiFormContainer";
import { AVM, Listing } from "../../../../../assets/images/api/APIimages";

const progress = ["Woning identificatie", "Resultaat"];

const ListingPriceContainer: React.FC<{
  children: ReactNode;
  page: number;
}> = ({ children, page }) => {
  return (
    <ApiFormContainer
      page={page}
      title="Vraagprijs API"
      subtitle="Bepaal een accurate vraagprijs voor een woning - De Vraagprijs API levert de vraagprijs van de ingevoerde Nederlandse woning op de opgegeven waarderingsdatum."
      progress={progress}
      resultSelector={(state) => state.listingPrice.result}
      link="https://docs.altum.ai/apis/listingprice-api"
      image={Listing}
    >
      {children}
    </ApiFormContainer>
  );
};

export default ListingPriceContainer;
