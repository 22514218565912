import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Loading from "../../components/Loading";
import OTPComponent from "../../components/OTPComponent";
import {
  loadUser,
  resendCode,
  verifyEmail,
} from "../../redux/actions/authActions";
import { P } from "../../styles/styled";
import { Colors } from "../../styles/Theme";
import AuthHeader from "./components/AuthHeader";
import Text from "../../components/Text";
import BackButton from "../../components/BackButton";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const VerifyEmail = () => {
  const query = useQuery();
  const history = useHistory();
  const email = query.get("email");
  const [queryEmail, setQueryEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [completeOtp, setCompleteOtp] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [started, setStarted] = useState(false);
  const dispatch = useAppDispatch();
  const { loading, user } = useAppSelector((state) => state.auth);
  const handleSubmit = useCallback(() => {
    email &&
      completeOtp &&
      dispatch(verifyEmail({ email, otp }))
        .then(() => setCompleteOtp(false))
        .then(() => dispatch(loadUser({ shouldLoadExtras: false })));
  }, [completeOtp, dispatch, email, otp]);

  useEffect(() => {
    email && setQueryEmail(email);
  }, [email]);

  useEffect(() => {
    user && user.active && history.push("/onboarding");
  }, [history, user]);

  // Submit the form when completeOtp is true
  useEffect(() => {
    if (completeOtp) {
      handleSubmit();
    }
  }, [completeOtp, handleSubmit]);

  useEffect(() => {
    let timerId: any;
    if (started && remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((time) => time - 1);
      }, 1000);
    } else if (remainingTime === 0) {
      setStarted(false);
    }

    return () => clearInterval(timerId); // cleanup function
  }, [started, remainingTime]);

  const startTimer = () => {
    setRemainingTime(60); // reset the timer
    setStarted(true);
  };

  return (
    <div className="w-full p-5 mt-[50px] gap-2 flex w-full flex-col justify-center">
      <BackButton />
      <AuthHeader
        title={"Eenmalige verificatie"}
        description={`We hebben een eenmalige verificatie code naar uw e-mail gestuurd`}
      >
        <span className="text-primary ">{email}</span>
      </AuthHeader>
      {loading ? (
        <Loading />
      ) : (
        <FormContainer className="mx-auto">
          <OTPComponent
            length={6}
            autoFocus
            onChangeOTP={(otp: string) => {
              setOtp(otp);
              if (otp.toString().length === 6) {
                setCompleteOtp(true);
              }
            }}
          />
          <ResendText className="text-sm">
            <Text className="text-gray-light">
              Ik heb geen verificatie code gekregen?
            </Text>
            <ResendButton
              onClick={() => {
                dispatch(resendCode(queryEmail))
                  .then(() => setCompleteOtp(false))
                  .then(() => {
                    startTimer();
                  });
              }}
              disabled={started}
            >
              {started
                ? `wacht ${remainingTime} ${
                    remainingTime === 1 ? "seconde" : "seconden"
                  }`
                : "Verzenden"}
            </ResendButton>
          </ResendText>
        </FormContainer>
      )}
      <div>
        <Textstyle> Altum AI B.V.</Textstyle>
      </div>
    </div>
  );
};

export default VerifyEmail;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 622px;
  height: 100%;
`;

const ResendText = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
  align-items: center;
`;

const ResendButton = styled.button`
  color: #27ae60;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
`;

const Textstyle = styled(P)`
  color: ${Colors.main.darkgrey};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0px 50px;

  @media (max-width: 960px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0px;
  }
`;
