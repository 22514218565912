import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "../redux/hooks";
import { getPrice } from "../helpers/stripeHelper";
import { MOPSUS } from "../helpers/stripePlanTypes";

interface ISubscriptionContext {
  apiCalls: number;
  altumPlan: string;
  setAltumPlan: React.Dispatch<React.SetStateAction<string>>;
  selectedPlan: string;
  setSelectedPlan: React.Dispatch<React.SetStateAction<string>>;
  ppuPrice: number;
  transactionPlan: string | undefined;
}

const SubscriptionContext = createContext<ISubscriptionContext | undefined>(
  undefined,
);
type Props = {
  children: ReactNode;
};

const SubscriptionProvider: FC<Props> = ({ children }) => {
  const {
    usage: { plan, used },
  } = useAppSelector((state) => state.apiUsage);

  const { comingInvoice, transactionComingInvoice } = useAppSelector(
    (state) => state.portal,
  );
  const defaultPlanName = "Altum AI - gratis account";

  const [altumPlan, setAltumPlan] = useState("-");
  const [selectedPlan, setSelectedPlan] = useState(MOPSUS._100);

  useEffect(() => {
    if (comingInvoice?.product.name) {
      setAltumPlan(comingInvoice.product.name);
    } else if (plan === "Platform - Free Tier") {
      setAltumPlan(defaultPlanName);
    }
  }, [comingInvoice?.product.name, plan]);
  const ppuPrice = getPrice(altumPlan);

  return (
    <SubscriptionContext.Provider
      value={{
        apiCalls: used || 0,
        altumPlan,
        setAltumPlan,
        selectedPlan,
        setSelectedPlan,
        ppuPrice,
        transactionPlan: transactionComingInvoice?.product.name,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error("Not in the Subscription context");
  }
  return context;
};

export { SubscriptionProvider, useSubscriptionContext };
