import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postAvmPlusDetails = createApiThunk(
  "avmplus/postAvmplusDetails",
  "api",
  "Avm Plus Api Used",
  "avmplus",
);

export const clearAvmPlusResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "avmplus/clearResults" });
};

export const modifyAvmPlusQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "avmplus/modifyQueries" });
};

export const avmPlusOneTimeUse = createApiThunk(
  "avmplus/avmOneTimeUse",
  "demo",
  "avm plus demo used",
  "avmplus",
);
