import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import ListingPriceContainer from "../components/ListingPriceContainer";
import ListingPriceResult from "./ListingPriceResult";

const ListingPriceResultPage = () => {
  const [page] = useState(2);

  return (
    <FormProvider>
      <ListingPriceContainer page={page}>
        <ListingPriceResult />
      </ListingPriceContainer>
    </FormProvider>
  );
};

export default ListingPriceResultPage;
