import { FC, useState } from "react";
import Text from "../../../../../components/Text";
import Subtitle from "../../../../../components/Subtitle";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import PDFPreview from "../pdf/PDFPreview";
import { AVMPDF } from "../pdf";
import { FaHome, FaEuroSign } from "react-icons/fa";
import { IoMdEye } from "react-icons/io";
import AVMPDFDownload from "../pdf/AVMPDFDownload";
import formatDate from "../../../../../helpers/convertToDutchMonth";

type Props = {
  property: any;
  buildingPhoto?: string;
  map?: string;
};

export const ResultSummary: FC<Props> = ({
  property,
  buildingPhoto = "",
  map = "",
}) => {
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const desc = [
    {
      icon: <FaEuroSign size={24} />,
      title: "Geschatte waarde",
      result: formatPrice(property?.price_estimation || 0),
    },
    {
      icon: <FaHome size={24} />,
      title: "Bouwjaar",
      result: property?.build_year || "-",
    },
  ];

  return (
    <>
      <div className="bg-white shadow-[0px_2px_8px_0px_#00000026] rounded-md md:flex md:flex-wrap justify-between p-4 md:p-6 absolute lg:top-[320px] lg:left-[250px] lg:w-[496px] md:top-[200px] md:left-[110px] md:w-[409px] top-[200px] left-[40px] w-[300px] grid grid-cols-2 gap-2">
        {desc.map(({ icon, title, result }, index) => (
          <div key={index} className="flex flex-col items-center gap-2">
            <Text className="">{title}</Text>
            <Subtitle className="flex text-base md:text-xl">{result}</Subtitle>
          </div>
        ))}
      </div>

      <div className="flex absolute lg:top-[390px] lg:left-[300px] md:top-[250px] md:left-[230px] top-[250px] left-[100px] gap-2">
        <Button
          type="button"
          onClick={() => setIsPreviewModalVisible(true)}
          className="ml-2 bg-white text-primary border border-primary flex items-center"
          size="xl"
        >
          <IoMdEye size={20} className="text-primary" />
          <span className="hidden lg:inline ml-2">Preview PDF</span>
        </Button>

        <AVMPDFDownload
          data={property}
          buildingPhoto={buildingPhoto}
          map={map}
        />
      </div>

      <Modal
        trigger={isPreviewModalVisible}
        setTrigger={setIsPreviewModalVisible}
      >
        <PDFPreview data={property} buildingPhoto={buildingPhoto} map={map} />
      </Modal>
    </>
  );
};
