import React, { FormEvent } from "react";
import Button from "../../../components/Button";

type Props = {
  children: React.ReactNode;
  cta?: string;
  isLoading: boolean;
  isError?: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

const AuthForm = (props: Props) => {
  return (
    <form
      className="flex flex-col mt-2 md:max-w-[424px] w-[340px] mx-auto"
      onSubmit={props.onSubmit}
    >
      {props.children}
      {props.cta && (
        <Button
          className="bg-primary"
          type="submit"
          isProcessing={props.isLoading}
          isError={props.isError}
        >
          {props.cta}
        </Button>
      )}
    </form>
  );
};

export default AuthForm;
