import { FC, useState } from "react";
import Text from "../../../../../components/Text";
import Subtitle from "../../../../../components/Subtitle";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import { FaHome, FaEuroSign } from "react-icons/fa";
import { IoMdEye } from "react-icons/io";
import { AVMResult } from "../types";
import AVMPDFDownload from "../../avmplus/pdf/AVMPDFDownload";
import { PDFPreview } from "../../avmplus/pdf";

type Props = {
  property: AVMResult;
  buildingPhoto?: string;
  map?: string;
};

export const ResultSummary: FC<Props> = ({
  property,
  buildingPhoto = "",
  map = "",
}) => {
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const mapPropertyData = (property: AVMResult) => {
    return {
      postcode: property.PostCode,
      housenumber: property.HouseNumber.toString(),
      houseaddition: property.HouseAddition || "",
      city: property.City,
      street: property.Street,
      housetype: property.HouseType,
      buildyear: property.BuildYear.toString(),
      inner_surface_area: property.InnerSurfaceArea.toString(),
      outer_surface_area: property.OuterSurfaceArea.toString(),
      volume: property.Volume.toString(),
      energy_label: property.EnergyLabel,
      longitude: property.Longitude.toString(),
      latitude: property.Latitude.toString(),
      valuation_date: property.ValuationDate.toString(),
      price_estimation: property.PriceEstimation.toString(),
      confidence: property.Confidence,
    };
  };

  const desc = [
    {
      icon: <FaEuroSign size={24} />,
      title: "Geschatte waarde",
      result: formatPrice(property?.PriceEstimation || 0),
    },
    {
      icon: <FaHome size={24} />,
      title: "Bouwjaar",
      result: property?.BuildYear || "-",
    },
  ];

  return (
    <>
      <div className="bg-white shadow-[0px_2px_8px_0px_#00000026] rounded-md md:flex md:flex-wrap justify-between p-4 md:p-6 absolute lg:top-[320px] lg:left-[250px] lg:w-[496px] md:top-[200px] md:left-[110px] md:w-[409px] top-[200px] left-[40px] w-[300px] grid grid-cols-2 gap-2">
        {desc.map(({ icon, title, result }, index) => (
          <div key={index} className="flex flex-col items-center gap-2">
            {/* {icon && <div className="text-primary">{icon}</div>} */}
            <Text className="">{title}</Text>
            <Subtitle className="flex text-base md:text-xl">{result}</Subtitle>
          </div>
        ))}
      </div>

      <div className="flex absolute lg:top-[390px] lg:left-[300px] md:top-[250px] md:left-[230px] top-[250px] left-[100px] gap-2">
        <Button
          type="button"
          onClick={() => setIsPreviewModalVisible(true)}
          className="ml-2 bg-white text-primary border border-primary flex items-center"
          size="xl"
        >
          <IoMdEye size={20} className="text-primary" />
          <span className="hidden lg:inline ml-2">Preview PDF</span>
        </Button>

        <AVMPDFDownload
          data={mapPropertyData(property)}
          buildingPhoto={buildingPhoto}
          map={map}
        />
      </div>

      <Modal
        trigger={isPreviewModalVisible}
        setTrigger={setIsPreviewModalVisible}
      >
        <PDFPreview
          data={mapPropertyData(property)}
          buildingPhoto={buildingPhoto}
          map={map}
        />
      </Modal>
    </>
  );
};

export default ResultSummary;
