import React, { useState, ReactNode, useEffect } from "react";
import DashboardContainer from "../../../Dashboard/components/Container";
import Header from "../../../Dashboard/components/Header";
import ProgressSection from "./ProgressSection";
import { useFormContext } from "./FormContext";
import ImageContainer from "./ImageContainer";
import JsonToggle from "./JsonToggle";
import JsonViewer from "./JsonViewer";
import { useAppSelector } from "../../../../redux/hooks";
import { ExplorOtherApis } from "../sustainability/components/Result";
import ImageSkeleton from "./ImageSkeleton";
import { stringToBoolean } from "../../../../helpers/common";
import { GiConsoleController } from "react-icons/gi";

interface ApiFormContainerProps {
  children: ReactNode;
  page: number;
  title: string;
  subtitle: string;
  progress: string[];
  resultSelector: (state: any) => any;
  showImageContainer?: boolean;
  link: string;
  image: string;
}

const ApiFormContainer: React.FC<ApiFormContainerProps> = ({
  children,
  page,
  title,
  subtitle,
  progress,
  resultSelector,
  showImageContainer = true,
  link,
  image,
}) => {
  const { formValues, buildingPhoto, houseAddress, fetchAddressData } =
    useFormContext();
  const result = useAppSelector(resultSelector);

  const [toggle, setToggle] = useState(false);
  const updatedData: Record<string, any> = {};
  if (formValues.post_code) {
    updatedData.post_code = formValues.post_code
      .split(" ")
      .join("")
      .toUpperCase();
  }

  Object.entries(formValues).forEach(([key, value]) => {
    if (value !== undefined) {
      updatedData[key] = stringToBoolean(value);
    }
  });

  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, fetchAddressData, buildingPhoto.length]);

  return (
    <DashboardContainer pageTitle={`${title} - Altum AI`}>
      <Header title={title} subtitle={subtitle} />
      <JsonToggle toggle={toggle} setToggle={setToggle} link={link} />
      <div className="flex flex-col gap-16 md:gap-8 max-w-[1100px] pt-4 md:pt-4 relative">
        {result.loading ? (
          <ImageSkeleton />
        ) : (
          showImageContainer &&
          page > 1 &&
          buildingPhoto &&
          !toggle && (
            <ImageContainer image={buildingPhoto} address={houseAddress} />
          )
        )}

        <div className="flex flex-col-reverse gap-1 lg:gap-8 lg:flex-row">
          {toggle ? (
            <JsonViewer
              showFormValues={page < progress.length}
              formValues={updatedData}
              result={result}
            />
          ) : (
            <div className="flex flex-col w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl max-w-[800px] md:max-h-[830px] p-3 lg:p-8 gap-8">
              {children}
            </div>
          )}

          <ProgressSection page={page} image={image} progress={progress} />
        </div>
        {page === progress.length && <ExplorOtherApis />}
      </div>
    </DashboardContainer>
  );
};

export default ApiFormContainer;
