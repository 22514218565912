import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import AVMContainer from "../components/AVMContainer";
import AVMResult from "./AVMResult";

const AvmPlusResultPage = () => {
  const [page] = useState(2);

  return (
    <FormProvider>
      <AVMContainer page={page}>
        <AVMResult />
      </AVMContainer>
    </FormProvider>
  );
};

export default AvmPlusResultPage;
