import { FC, useState } from "react";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";

interface DisableTwoFactorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (password: string) => Promise<void>;
}

const DisableTwoFactorModal: FC<DisableTwoFactorModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDisable = async () => {
    if (!password) {
      setError("Voer uw wachtwoord in");
      return;
    }

    try {
      setError("");
      setLoading(true);
      await onConfirm(password);
      onClose();
    } catch (err: any) {
      setError(err.response?.data?.message || "Kan 2FA niet uitschakelen");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      trigger={isOpen}
      setTrigger={onClose}
      header="Twee-factor authenticatie uitschakelen"
    >
      <div className="p-6">
        <p className="text-gray-600 mb-4">
          Voer uw wachtwoord in om twee-factor authenticatie uit te schakelen.
        </p>
        <TextInput
          type="password"
          placeholder="Voer uw wachtwoord in"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          message={error}
        />
        <div className="mt-6 flex justify-end space-x-3">
          <Button
            onClick={onClose}
            disabled={loading}
            className="bg-gray-100 text-gray-800 hover:bg-gray-200"
          >
            Annuleren
          </Button>
          <Button
            onClick={handleDisable}
            disabled={loading}
            className={`${loading ? "opacity-50" : ""} bg-primary text-white`}
          >
            {loading ? "Uitschakelen..." : "2FA uitschakelen"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisableTwoFactorModal;
