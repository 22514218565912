import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import {
  loadUser,
  logout,
  signIn,
  verify2FA,
  request2FACode,
  SignInPayload,
  signUp,
  verifyEmail,
  TwoFARequiredResponse,
} from "../actions/authActions";
import { User } from "../../@types";

// Auth slice
interface AuthState {
  initialLoading: boolean;
  loading: boolean;
  isAuthenticated: boolean;
  id: string;
  user: User | null;
  error: string | null | unknown;
  verified: boolean;
  // 2FA specific fields
  requires2FA: boolean;
  userId?: string;
  twoFactorType?: "email" | "authenticator";
}

const initialState: AuthState = {
  initialLoading: true,
  loading: false,
  id: "",
  isAuthenticated: false,
  user: null,
  error: null,
  verified: false,
  requires2FA: false,
};

const cookie = new Cookies();

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearResults: (state) => {
      state.id = "";
      state.loading = false;
    },
    clear2FAState: (state) => {
      state.requires2FA = false;
      state.userId = undefined;
      state.twoFactorType = undefined;
    },
    set2FAState: (
      state,
      action: PayloadAction<{
        requires2FA: boolean;
        userId?: string;
        twoFactorType?: "email" | "authenticator";
      }>,
    ) => {
      state.requires2FA = action.payload.requires2FA;
      state.userId = action.payload.userId;
      state.twoFactorType = action.payload.twoFactorType;
    },
  },
  extraReducers: (builder) => {
    // signUp
    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.loading = false;
      state.id = action.payload;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // signIn
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      signIn.fulfilled,
      (state, action: PayloadAction<SignInPayload | TwoFARequiredResponse>) => {
        state.loading = false;
        if (
          "status" in action.payload &&
          action.payload.status === "2fa_required"
        ) {
          // Handle 2FA required response
          state.requires2FA = true;
          state.userId = action.payload.userId;
          state.twoFactorType = action.payload.type;
          state.isAuthenticated = false;
        } else if ("user" in action.payload && action.payload.user) {
          // Handle successful login
          state.isAuthenticated = true;
          state.user = action.payload.user;
          state.requires2FA = false;
          state.userId = undefined;
          state.twoFactorType = undefined;
          if (action.payload.token) {
            cookie.set("x-mopsus-jwt", action.payload.token);
            localStorage.setItem("token", action.payload.token);
          }
        }
        state.error = null;
      },
    );
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.requires2FA = false;
      state.userId = undefined;
      state.twoFactorType = undefined;
    });

    // loadUser
    builder.addCase(loadUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadUser.fulfilled, (state, action) => {
      state.loading = false;
      state.initialLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload || null;
      state.error = null;
    });
    builder.addCase(loadUser.rejected, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.initialLoading = false;
      state.user = null;
      cookie.remove("x-mopsus-jwt");
      localStorage.removeItem("token");
    });

    // logout
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.requires2FA = false;
      state.userId = undefined;
      state.twoFactorType = undefined;
      cookie.remove("x-mopsus-jwt");
      localStorage.removeItem("token");
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
    });

    // Verify 2FA
    builder.addCase(verify2FA.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(verify2FA.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.requires2FA = false;
      state.userId = undefined;
      state.twoFactorType = undefined;
      cookie.set("x-mopsus-jwt", action.payload.token);
      localStorage.setItem("token", action.payload.token);
    });
    builder.addCase(verify2FA.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Request 2FA code
    builder.addCase(request2FACode.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(request2FACode.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(request2FACode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { clearResults, clear2FAState, set2FAState } = authSlice.actions;
export default authSlice.reducer;
