import { FC, useState } from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import Text from "../../../../../components/Text";
import Subtitle from "../../../../../components/Subtitle";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";
import {
  MdOutlineRealEstateAgent,
  MdLocationOn,
  MdOutlineEuro,
} from "react-icons/md";
import { RiHomeSmileLine } from "react-icons/ri";
import formatDate from "../../../../../helpers/convertToDutchMonth";

type Props = {
  property: any;
  buildingPhoto?: string;
  map?: string;
};

const Result: FC<Props> = ({ property }) => {
  const overview = [
    {
      category: "Woningdetails",
      icon: <MdOutlineRealEstateAgent className="w-8 h-8" />,
      data: [
        {
          title: "Woningdetails",
          details: [
            {
              key: "Woningtype",
              value: property.house_type || "-",
              unit: "",
            },
            {
              key: "Bouwjaar",
              value: property.build_year || "-",
              unit: "",
            },
            {
              key: "Woonoppervlakte",
              value: convertToEuFormat(property.inner_surface_area) || "-",
              unit: "m²",
            },
            {
              key: "Perceeloppervlakte",
              value: convertToEuFormat(property.outer_surface_area) || "-",
              unit: "m²",
            },
          ],
        },
      ],
    },
    {
      category: "Locatie",
      icon: <MdLocationOn className="w-8 h-8" />,
      data: [
        {
          title: "Locatiegegevens",
          details: [
            {
              key: "Adres",
              value:
                `${property.street} ${property.housenumber} ${property.houseaddition} ${property.city}` ||
                "-",
              unit: "",
            },
            {
              key: "Huisnummer",
              value:
                `${property.housenumber}${property.houseaddition || ""}` || "-",
              unit: "",
            },
            {
              key: "Postcode",
              value: property.postcode || "-",
              unit: "",
            },
          ],
        },
      ],
    },
    {
      category: "Energielabel",
      icon: <RiHomeSmileLine className="w-8 h-8" />,
      data: [
        {
          title: "Energielabel",
          details: [
            {
              key: "Huidig energielabel",
              value: property.energy_label || "-",
              unit: "",
            },
          ],
        },
      ],
    },
    {
      category: "Waardering",
      icon: <MdOutlineEuro className="w-8 h-8" />,
      data: [
        {
          title: "Waarderingsdetails",
          details: [
            {
              key: "Geschatte waarde",
              value: convertToEuFormat(property.price_estimation) || "-",
              unit: "€",
            },
            {
              key: "Betrouwbaarheid",
              value: property.confidence
                ? `90% van € ${convertToEuFormat(
                    Math.ceil(
                      parseFloat(property.price_estimation.toString()) * 0.9,
                    ),
                  )} tot € ${convertToEuFormat(
                    Math.ceil(
                      parseFloat(property.price_estimation.toString()) * 1.1,
                    ),
                  )}`
                : "-",
              unit: "",
            },
            {
              key: "Waarderingsdatum",
              value: formatDate(property.valuation_date) || "-",
              unit: "",
            },
          ],
        },
      ],
    },
  ];

  const [activeResult, setActiveResult] = useState<any>(overview[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const popupDetails = (
    <div
      className={`md:shadow-[0px_2px_8px_0px_#00000026] md:rounded-md ${
        Object.keys(activeResult).length > 0
          ? "w-full h-full max-h-[600px] p-4 overflow-auto"
          : "w-0"
      } `}
    >
      {Object.keys(activeResult).length > 0 && (
        <>
          {activeResult?.data?.map((res: any, index: number) => (
            <div key={index} className="mt-6">
              <div className="flex gap-2 items-center">
                <Subtitle className="text-base">{res.title}</Subtitle>
              </div>

              {res.details.map((detail: any, idx: number) => (
                <div className="flex justify-between mt-4" key={idx}>
                  <Text className="text-gray-light">{detail.key}</Text>
                  <Text className="text-gray-dark font-medium">
                    {detail.unit && detail.unit !== "€"
                      ? `${detail.value} ${detail.unit}`
                      : detail.unit === "€"
                      ? `${detail.unit} ${detail.value}`
                      : detail.value}
                  </Text>
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <div className="md:flex md:gap-8 md:justify-between">
      <div className="flex flex-col gap-4">
        {overview.map((res, idx) => (
          <>
            <div
              className={`shadow-[0px_2px_8px_0px_#00000026] rounded-md flex justify-between items-center w-full min-w-[300px] h-[72px] p-3 cursor-pointer`}
              key={idx}
              onClick={() => {
                setActiveResult(res);
                setActiveIndex(idx);
              }}
            >
              <div className={`flex items-center gap-3`}>
                <div
                  className={`${
                    idx === activeIndex ? "text-[#FFC694]" : "text-[#FFC694]/50"
                  }`}
                >
                  {res.icon}
                </div>

                <Text
                  className={`font-semibold text-base ${
                    idx === activeIndex ? "text-gray-dark" : "text-gray-light"
                  }`}
                >
                  {res.category}
                </Text>
              </div>

              {idx === activeIndex ? (
                <IoMdArrowDropleft className="w-6 h-6 text-primary" />
              ) : (
                <IoMdArrowDropright className="w-6 h-6 text-gray-light" />
              )}
            </div>

            <div className={`md:hidden`}>
              {idx === activeIndex && popupDetails}
            </div>
          </>
        ))}
      </div>

      <div className="hidden md:block w-full">{popupDetails}</div>
    </div>
  );
};

export default Result;
