import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useFormContext } from "../../components/FormContext";
import { Redirect, useHistory } from "react-router-dom";
import RedoButton from "../../components/RedoButtons";
import ResultSkeleton from "../../sustainability/components/ResultSkeleton";
import Result from "../components/Result";
import { ResultSummary } from "../components/ResultSummary";
import {
  clearListingPriceResults,
  modifyListingPriceQueries,
} from "../../../../../redux/actions/listingPriceActions";
import type { ListingPriceResult as ListingPriceResultType } from "../types";
import { ApiInitState } from "../../../../../helpers/createApiSlice";

const ListingPriceResult = () => {
  const { result, loading } = useAppSelector(
    (state) => state.listingPrice as ApiInitState<ListingPriceResultType>,
  );
  const { setPostalAddress, buildingPhoto, map } = useFormContext();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearListingPriceResults());
    history.push("/listingprice");
  };

  const modifyResults = () => {
    dispatch(modifyListingPriceQueries());
    history.push("/listingprice");
  };

  useEffect(() => {
    if (result && Object.keys(result).length > 0) {
      setPostalAddress(
        `${result.postcode}-${result.housenumber}-${
          result.houseaddition || ""
        }`,
      );
    }
  }, [result, setPostalAddress]);

  if (Object.keys(result).length === 0 && !loading) {
    return <Redirect to="/listingprice" />;
  }

  if (loading) {
    return <ResultSkeleton />;
  }

  return (
    <>
      <ResultSummary
        property={result}
        buildingPhoto={buildingPhoto}
        map={map}
      />
      <Result property={result} buildingPhoto={buildingPhoto} map={map} />
      <RedoButton modify={modifyResults} clear={clearResults} />
    </>
  );
};

export default ListingPriceResult;
