import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import ListingPriceContainer from "../components/ListingPriceContainer";
import ListingPriceForm from "./ListingPriceForm";

const ListingPrice = () => {
  const [page, setPage] = useState(1);

  return (
    <FormProvider>
      <ListingPriceContainer page={page}>
        <ListingPriceForm page={page} setPage={setPage} />
      </ListingPriceContainer>
    </FormProvider>
  );
};

export default ListingPrice;
