import createApiSlice from "../../helpers/createApiSlice";
import { avmOneTimeUse, postAvmDetails } from "../actions/avmActions";
import { AVMResult } from "../../pages/Api/usage-pages/avm/types";

const avmSlice = createApiSlice<AVMResult>(
  "avm",
  postAvmDetails,
  avmOneTimeUse,
);

export const { clearResults, modifyQueries } = avmSlice.actions;

export default avmSlice.reducer;
