import { CiLock, CiMail } from "react-icons/ci";
import DividerWithText from "../../../../components/DividerWithText";
import TextInput from "../../../../components/TextInput";
import AuthHeader from "../../components/AuthHeader";
import Social from "../../components/Social";
import Text from "../../../../components/Text";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthForm from "../../components/AuthForm";
import AuthContainer from "../../components/AuthContainer";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { useValidation } from "../../../../hooks/useValidator";
import { signIn } from "../../../../redux/actions/authActions";
import { set2FAState } from "../../../../redux/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import TwoFactorVerify from "./TwoFactorVerify";

interface AuthFormData {
  email: string;
  password: string;
}

const SigninRightSection = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isAuthenticated, loading, requires2FA, userId, twoFactorType } =
    useAppSelector((state) => state.auth);
  const [formData, setFormData] = useState<AuthFormData>({
    email: "",
    password: "",
  });
  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const { errors, validate } = useValidation();
  const [showPassword, setShowPassword] = useState(false);

  // Use a ref to track redirect attempts and prevent multiple redirects
  const redirectAttempted = useRef(false);

  useEffect(() => {
    // Using localStorage instead of sessionStorage for more persistent tracking
    const hasRedirected =
      localStorage.getItem("dashboardRedirected") === "true";

    // Only redirect if:
    // 1. User is authenticated
    // 2. 2FA is not required
    // 3. Loading is complete
    // 4. No redirection has been attempted during this authentication session
    // 5. No redirection has already occurred (stored in localStorage)
    if (
      isAuthenticated &&
      !requires2FA &&
      !loading &&
      !redirectAttempted.current &&
      !hasRedirected
    ) {
      // Set both flags to prevent future redirects
      redirectAttempted.current = true;
      localStorage.setItem("dashboardRedirected", "true");

      console.log("Redirecting to dashboard...");

      // Use replace with a single call to prevent navigation issues
      history.replace("/dashboard/startpagina");
    }

    // Reset flags ONLY when authentication state actually changes to not authenticated
    // This prevents clearing flags during component re-renders when still authenticated
    if (!isAuthenticated && (redirectAttempted.current || hasRedirected)) {
      redirectAttempted.current = false;
      localStorage.removeItem("dashboardRedirected");
    }
  }, [isAuthenticated, requires2FA, loading, history]);

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouchedFields((prev) => ({ ...prev, [name]: true }));
      validate(formData);
    },
    [formData, validate],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (touchedFields[name]) {
        validate({ ...formData, [name]: value });
      }
    },
    [touchedFields, formData, validate],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        await dispatch(signIn({ formData, dispatch })).unwrap();
      } catch (error) {
        console.error("Login error:", error);
      }
    },
    [dispatch, formData],
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userParam = params.get("user");
    if (userParam) {
      try {
        const userData = JSON.parse(decodeURIComponent(userParam));
        if (userData.requires2FA && userData.userId && userData.type) {
          dispatch(
            set2FAState({
              requires2FA: true,
              userId: userData.userId,
              twoFactorType: userData.type,
            }),
          );
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, [location.search, dispatch]);

  const handle2FAVerified = useCallback(() => {
    // Clear any existing dashboard redirect flag
    redirectAttempted.current = false;
    localStorage.removeItem("dashboardRedirected");

    // Force immediate redirect to dashboard using a timeout to ensure state updates complete first
    const redirectTimer = setTimeout(() => {
      if (!redirectAttempted.current) {
        redirectAttempted.current = true;
        history.replace("/dashboard/startpagina");
      }
    }, 0);
    return () => clearTimeout(redirectTimer);
  }, [history]);
  console.log("requires2FA", requires2FA);

  if (requires2FA && userId && twoFactorType) {
    return (
      <TwoFactorVerify
        userId={userId}
        twoFactorType={twoFactorType}
        onVerifySuccess={handle2FAVerified}
      />
    );
  }

  return (
    <AuthContainer>
      <AuthHeader
        title={"Log in op jouw account"}
        description={"Selecteer een methode om in te loggen."}
      />

      <Social />
      <DividerWithText
        className="md:max-w-[424px] w-[340px]"
        text={"of log in met e-mail"}
      />
      <AuthForm
        cta="Inloggen"
        onSubmit={handleSubmit}
        isLoading={loading}
        isError={!!errors["email"] || !!errors["password"]}
      >
        <TextInput
          type="email"
          name="email"
          placeholder="Email"
          className="mt-3"
          icon={CiMail}
          message={touchedFields.email ? errors["email"] : ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="relative">
          <TextInput
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Wachtwoord (8+ tekens)"
            className="mt-3"
            icon={CiLock}
            message={touchedFields.password ? errors["password"] : ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 mt-1"
          >
            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </button>
        </div>
        <Text className="text-center mt-1 text-xs">
          Wachtwoord vergeten?{" "}
          <Link
            to="/forgot-password"
            className="text-green-600 hover:underline text-xs"
          >
            Wachtwoord opnieuw instellen
          </Link>
          .
        </Text>
      </AuthForm>

      <Text className="text-center mt-4">
        Nog geen account?{" "}
        <Link to="/" className="text-green-600 hover:underline">
          Aanmelden
        </Link>
        .
      </Text>
    </AuthContainer>
  );
};

export default SigninRightSection;
