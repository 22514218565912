import React, { FC } from "react";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { useFormContext } from "../../components/FormContext";
import { MultiValue, ActionMeta } from "react-select";
import { InputTypes } from "../../../../../components/ReturnInputField";
import DatePicker from "../../../../../components/DatePicker";

type Option = {
  label: string;
  value: string | number;
};

const IdentifyProperty: FC = () => {
  const { formValues, setFormValues } = useFormContext();

  const handleDateChange = (formattedDate: string) => {
    setFormValues((prev) => ({ ...prev, valuation_date: formattedDate }));
  };

  const handleInputChange = (
    name: string,
    value: string | number | Option | MultiValue<Option>,
  ) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleStandardInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | MultiValue<Option>
      | Option,
    actionMeta?: ActionMeta<Option>,
  ) => {
    if ("target" in event) {
      const { name, value, type } = event.target;
      const parsedValue = type === "number" ? parseFloat(value) || "" : value;
      handleInputChange(name, parsedValue);
    } else if (Array.isArray(event)) {
      // Handle multi-select
      const name = actionMeta?.name;
      if (name) {
        setFormValues((prev) => ({
          ...prev,
          [name]: event,
        }));
      }
    } else if ("value" in event) {
      // Handle single select option
      const name = actionMeta?.name;
      if (name) {
        setFormValues((prev) => ({
          ...prev,
          [name]: event.value,
        }));
      }
    }
  };

  return (
    <div className="mt-6 w-full max-w-[496px]">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <ReturnInputField
            label="Postcode*"
            name="postcode"
            placeholder="1234AB"
            value={formValues.postcode || ""}
            onChange={handleStandardInputChange}
          />
        </div>
        <div className="col-span-1">
          <ReturnInputField
            label="Huisnummer*"
            name="housenumber"
            placeholder="1"
            value={formValues.housenumber || ""}
            onChange={handleStandardInputChange}
          />
        </div>
        <div className="col-span-1">
          <ReturnInputField
            label="Toevoeging"
            name="houseaddition"
            placeholder="A"
            value={formValues.houseaddition || ""}
            onChange={handleStandardInputChange}
          />
        </div>
        <div className="col-span-1">
          <DatePicker
            label="Waarderingsdatum"
            value={formValues.valuation_date}
            className="w-full"
            onChange={handleDateChange}
          />
        </div>
        <div className="col-span-1">
          <ReturnInputField
            label="Energielabel"
            name="energy_label"
            type={InputTypes.SLIDER}
            value={formValues.energy_label || ""}
            onChange={handleStandardInputChange}
          />
        </div>
        <div className="col-span-1">
          <ReturnInputField
            label="Woonoppervlakte (m²)"
            name="inner_surface_area"
            type="number"
            placeholder="100"
            value={formValues.inner_surface_area || ""}
            onChange={handleStandardInputChange}
          />
        </div>
        <div className="col-span-1">
          <ReturnInputField
            label="Perceeloppervlakte (m²)"
            name="outer_surface_area"
            type="number"
            placeholder="150"
            value={formValues.outer_surface_area || ""}
            onChange={handleStandardInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default IdentifyProperty;
