import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit";
import {
  getApisAnalytics,
  getApisTotalCalls,
  getUsage,
  getUsageLogs,
} from "../actions/apiUsage";

// Add constant for allowed API names
export const ALLOWED_API_NAMES = [
  "Location-Data",
  "woz",
  "Geometry",
  "CreditAPI",
  "amenities",
  "Solar API",
  "EnergyClimateAPI",
  "Interactive Reference",
  "Rebuild",
  "autosuggest",
  "ObjectData",
  "location",
  "Condition-Score",
  "move-data",
  "listingprice",
  "EnergyLabelInsights",
  "Energy Label API",
  "Reference",
  "Label API",
  "WWSPoints",
  "Rental-Reference-API",
  "AVM+",
  "bag",
  "avm",
  "Sustainability",
  "TransactionAPI",
] as const;

export type AllowedApiName = (typeof ALLOWED_API_NAMES)[number];

// Add type for analytics statistics
interface ApiStatistic {
  name: string;
  total: number;
  day: string;
  calls: number;
}

export interface ApiUsageState {
  usage: {
    loading: boolean;
    plan?: string;
    totalCredits?: string | number;
    remaining?: number | string;
    used?: number;
    subscription?: boolean;
    usageToday?: number;
    usageYesterday?: number;
    usageLastWeek?: number;
    usageThisWeek?: number;
    transactionUsed?: number;
    transactionSubscription?: boolean;
    transactionUsageToday?: number;
    transactionUsageYesterday?: number;
    transactionUsageThisWeek?: number;
    transactionUsageLastWeek?: number;
    allowedApiNames?: string[];
  };
  usageLogs: { loading: boolean; logs: any[] };
  error: string | null;
  totalApiCallsSum: number;
  analytics: {
    loading: boolean;
    statistics: ApiStatistic[];
  };
  responseStatus: {
    "400": number;
    "422": number;
    "429": number;
    "500": number;
    success: number;
  };
  allowedApis: string[];
}

const initialState: ApiUsageState = {
  usage: {
    loading: false,
    plan: "-",
    totalCredits: "-",
    remaining: "-",
    used: 0,
    transactionUsed: 0,
    subscription: false,
    transactionSubscription: false,
    usageToday: 0,
    usageYesterday: 0,
    usageLastWeek: 0,
    usageThisWeek: 0,
    transactionUsageToday: 0,
    transactionUsageYesterday: 0,
    transactionUsageThisWeek: 0,
    transactionUsageLastWeek: 0,
  },
  usageLogs: { loading: false, logs: [] },
  error: null,
  totalApiCallsSum: 0,
  analytics: {
    loading: false,
    statistics: [],
  },
  responseStatus: {
    "400": 0,
    "422": 0,
    "429": 0,
    "500": 0,
    success: 0,
  },
  allowedApis: [] as string[], // Add new state for allowed APIs
};

export type RootState = {
  apiUsage: typeof initialState;
};

const usageSlice = createSlice({
  name: "apiUsage",
  initialState,
  reducers: {
    clearState: (state) => {
      state = initialState;
    },
    setAllowedApis: (state, action: PayloadAction<string[]>) => {
      state.allowedApis = action.payload.filter((name) =>
        ALLOWED_API_NAMES.includes(name as AllowedApiName),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsage.pending, (state) => {
        state.usage.loading = true;
      })
      .addCase(getUsage.fulfilled, (state, action: PayloadAction<any>) => {
        state.usage.loading = false;
        state.usage = action.payload;
        // Set allowed APIs from the response
        state.allowedApis = action.payload.allowedApiNames || [];
      })
      .addCase(getUsage.rejected, (state) => {
        state.usage.loading = false;
        state.error = "Failed to load usage data";
      })

      .addCase(getApisTotalCalls.pending, (state) => {
        state.usage.loading = true;
      })
      .addCase(
        getApisTotalCalls.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.usage.loading = false;
          state.totalApiCallsSum = action.payload;
        },
      )
      .addCase(getApisTotalCalls.rejected, (state) => {
        state.usage.loading = false;
        state.error = "Failed to load total API calls";
      })

      .addCase(getApisAnalytics.pending, (state) => {
        state.analytics.loading = true;
      })
      .addCase(
        getApisAnalytics.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.analytics.loading = false;
          state.analytics.statistics = action.payload.statistics;
          state.responseStatus = action.payload.responseStatus;
        },
      )
      .addCase(getApisAnalytics.rejected, (state) => {
        state.analytics.loading = false;
        state.error = "Failed to load API analytics";
      })
      .addCase(getUsageLogs.pending, (state) => {
        state.usageLogs.loading = true;
      })
      .addCase(getUsageLogs.fulfilled, (state, action: PayloadAction<any>) => {
        state.usageLogs.loading = false;
        state.usageLogs.logs = action.payload;
      })
      .addCase(getUsageLogs.rejected, (state) => {
        state.usageLogs.loading = false;
        state.error = "Failed to get usage logs.";
      });
  },
});

// Add selector for filtered APIs
export const selectAllowedApis = (state: RootState) =>
  state.apiUsage.allowedApis;

export const selectFilteredAnalytics = createSelector(
  [
    (state: RootState) => state.apiUsage.analytics.statistics,
    selectAllowedApis,
  ],
  (statistics: ApiStatistic[], allowedApis: string[]) =>
    statistics.filter((stat) => allowedApis.includes(stat.name)),
);

export const { clearState, setAllowedApis } = usageSlice.actions;
export default usageSlice.reducer;
