import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postListingPriceDetails = createApiThunk(
  "listingprice/postListingPriceDetails",
  "api",
  "Listing Price Api Used",
  "listingprice",
);

export const clearListingPriceResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "listingprice/clearResults" });
};

export const modifyListingPriceQueries =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "listingprice/modifyQueries" });
  };

export const listingPriceOneTimeUse = createApiThunk(
  "listingprice/listingPriceOneTimeUse",
  "demo",
  "listing price demo used",
  "listingprice",
);
