import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Message } from "../../@types";
import { AppDispatch } from "../store";

interface MessagePayload {
  status: string;
  messagesTotal: number;
  resultsPerPage: number;
  unreadMessages: number;
  page: number;
  data: {
    userId: string;
    messages: Message[];
  };
}
export const getAllMessagesUser = createAsyncThunk(
  "messages/getAllMessagesUser",
  async (arg: { userId: string; currentPage?: number; offset?: number }) => {
    const { userId, currentPage = 1, offset = 0 } = arg;
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get<MessagePayload>(
      `/api/v1/messages/users/${userId}${
        offset ? `/?offset=${offset}&page=${currentPage}` : ""
      }`,
      config,
    );

    const { messages } = res.data.data;
    return {
      messages,
      messagesTotal: res.data.messagesTotal,
      page: res.data.page,
    };
  },
);

export const getAllUnreadMessagesUser = createAsyncThunk<
  any,
  { userId: string }
>("messages/getAllUnreadMessagesUser", async ({ userId }, thunkApi) => {
  // Add throttling to prevent repeated calls in short succession
  // Use a timestamp in localStorage to track the last time this action was called
  const lastCallTime = localStorage.getItem("lastUnreadMessagesCall");
  const currentTime = Date.now();

  // Only allow this call once every 2 seconds to prevent loops
  if (lastCallTime && currentTime - parseInt(lastCallTime) < 2000) {
    console.log("Throttling getAllUnreadMessagesUser to prevent loops");
    return thunkApi.rejectWithValue("Action throttled to prevent loops");
  }

  // Update the timestamp for the next check
  localStorage.setItem("lastUnreadMessagesCall", currentTime.toString());

  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get<MessagePayload>(
      `/api/v1/messages/users/${userId}`,
      config,
    );

    const { messages } = res.data.data;
    const unread = res.data.unreadMessages;
    thunkApi.dispatch(setCurrentPage(res.data.page));
    return { messages, messagesTotal: res.data.messagesTotal, unread };
  } catch (error) {
    console.error("Error fetching unread messages:", error);
    return thunkApi.rejectWithValue("Failed to fetch unread messages");
  }
});

export const setCurrentPage = createAsyncThunk(
  "messages/setCurrentPage",
  async (page: number) => page,
);

export const setCurrentMessage = createAsyncThunk(
  "messages/setCurrentMessage",
  async (message: {
    id: string;
    title: string;
    message: string;
    created_at: string;
    opened: boolean;
  }) => message,
);

export const clearCurrentMessage = createAsyncThunk(
  "messages/clearCurrentMessage",
  async (message: { id: string; title: string; message: string }) => ({
    payload: message,
  }),
);

export const markAllAsRead = createAsyncThunk<
  any,
  { userId: string; dispatch: AppDispatch }
>("messages/markAllAsRead", async ({ userId, dispatch }) => {
  const config = { headers: { "Content-Type": "application/json" } };
  await axios.patch(
    `/api/v1/messages/mark-all-read/users/${userId}`,
    {},
    config,
  );
  dispatch(getAllMessagesUser({ userId }));
});

export const markMessageAsRead = createAsyncThunk<
  any,
  { messageId: string; userId: string; dispatch: AppDispatch }
>("messages/markMessageAsRead", async (arg) => {
  const { messageId, userId, dispatch } = arg;
  const config = { headers: { "Content-Type": "application/json" } };
  const res = await axios.patch<MessagePayload>(
    `/api/v1/messages/${messageId}/${userId}`,
    {},
    config,
  );
  const unread = res.data.unreadMessages;
  dispatch(getAllMessagesUser({ userId }));
  return unread;
});

export const deleteCurrentMessage = createAsyncThunk(
  "messages/deleteCurrentMessage",
  async (arg: {
    message: { id: string; title: string; message: string };
    userId: string;
    dispatch: AppDispatch;
  }) => {
    const { message, userId, dispatch } = arg;
    const config = { headers: { "Content-Type": "application/json" } };
    const data: { messageIds: string[]; userId: string } = {
      messageIds: [],
      userId,
    };
    data.messageIds.push(message.id);
    await axios.patch("/api/v1/messages/delete-user-messages", data, config);

    dispatch(clearCurrentMessage(message));
    dispatch(getAllMessagesUser({ userId }));
  },
);

export const addSelectedMessages = createAsyncThunk(
  "messages/addSelectedMessages",
  async (messageId: string) => messageId,
);

export const removeSelectedMessages = createAsyncThunk(
  "messages/removeSelectedMessages",
  (messageId: string) => messageId,
);

export const deleteSelectedMessages = createAsyncThunk(
  "messages/deleteSelectedMessages",
  async (arg: {
    selectedMessages: string[];
    userId: string;
    dispatch: AppDispatch;
  }) => {
    const { selectedMessages, userId, dispatch } = arg;
    const config = { headers: { "Content-Type": "application/json" } };
    const data: { messageIds: string[]; userId: string } = {
      messageIds: [],
      userId,
    };
    data.messageIds = selectedMessages;

    await axios.patch("/api/v1/messages/delete-user-messages", data, config);
    dispatch(getAllMessagesUser({ userId }));
  },
);
