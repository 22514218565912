import React, { Suspense, useEffect, useMemo, useState } from "react";
import {
  AnalyticsState,
  getApisAnalytics,
} from "../../../redux/actions/apiUsage";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { findTopFiveApiUsage } from "../../../helpers/rankAPIByUsage";
import { filterAnylyticsData } from "../../../helpers/filterAnalyticData";
import DashboardContainer from "../components/Container";
import Header from "../components/Header";
import CardContainer from "../components/CardContainer";
import APILogs from "../startinpagina/components/APILogs";
import BarChartSkeleton from "./components/skeleton-loaders/BarChartSkeleton";
import PieChatSkeleton from "./components/skeleton-loaders/PieChatSkeleton";
import UsedAPIListSkeleton from "./components/skeleton-loaders/UsedAPIListSkeleton";
import apiColorPicker from "../../../helpers/apiColorPicker";
import apiNameConverter from "../../../helpers/apiNameConverter";
import Text from "../../../components/Text";
import { knowWhen } from "../../../helpers/time";
import { Log } from "../../../@types";
import {
  selectAllowedApis,
  selectFilteredAnalytics,
} from "../../../redux/features/apiUsageSlice";

interface ApiData {
  name: string;
  total: number;
  day: string;
  calls: number;
}

const Chart = React.lazy(() => import("./components/chart/Chart"));
const PieChartComponent = React.lazy(
  () => import("./components/chart/PieChatComponent"),
);

const filterDays: { [key: string]: string } = {
  "Afgelopen 30 dagen": "30",
  "Afgelopen week": "7",
  Gisteren: "2",
  Vandaag: "1",
};

function Analytics() {
  const [apiData, setApiData] = useState<ApiData[]>([]);
  const [apiStatus, setApiStatus] = useState<AnalyticsState["responseStatus"]>({
    "400": 0,
    "422": 0,
    "429": 0,
    "500": 0,
    success: 0,
  });
  const [days, setDays] = useState("30");
  const [period, setPeriod] = useState<string>("Afgelopen 30 dagen");
  const periods = ["Vandaag", "Gisteren", "Deze week", "Afgelopen 30 dagen"];
  const dispatch = useAppDispatch();
  const { apiUsage, auth } = useAppSelector((state) => state);
  const apiKey = auth.user?.api_key;
  const transactionApiKey = auth.user?.transaction_api_key;

  const pieData = useMemo(() => {
    return Object.entries(apiStatus).map(([key, value]) => ({
      name: key,
      value: value,
    }));
  }, [apiStatus]);

  const formattedLogs = useMemo(
    () =>
      apiUsage.usageLogs.logs?.map((log: Log) => ({
        ...log,
        api_name: apiNameConverter(log.api_name),
        requestTime: knowWhen(log.requestTime),
      })),
    [apiUsage.usageLogs.logs],
  );

  useEffect(() => {
    apiKey && dispatch(getApisAnalytics({ days, apiKey, transactionApiKey }));
  }, [apiKey, days, dispatch, transactionApiKey]);

  useEffect(() => {
    setApiData(apiUsage.analytics.statistics);
    setApiStatus(apiUsage.responseStatus);
  }, [apiUsage.responseStatus, apiUsage.analytics.statistics]);

  useEffect(() => {
    setDays(filterDays[period]);
  }, [period]);

  const called = filterAnylyticsData(apiData);
  const topApis = findTopFiveApiUsage(called);

  const NoDataPieChart = () => (
    <div className="flex flex-col items-center justify-center h-full min-h-[300px] gap-2">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2897_1323)">
          <path
            d="M58 35.036C58 34.7708 57.8946 34.5164 57.7071 34.3289C57.5196 34.1414 57.2652 34.036 57 34.036H30V7C30 6.73478 29.8946 6.48043 29.7071 6.29289C29.5196 6.10536 29.2652 6 29 6C13.01 6 0 18.99 0 34.958C0 51.514 12.486 64 29.042 64C44.468 64 58 50.464 58 35.036ZM2 34.958C2 20.426 13.574 8.546 28 8.018V35.036C28 35.588 28.448 36.036 29 36H55.98C55.414 49.98 43.07 62 29.042 62C13.626 62 2 50.376 2 34.958Z"
            fill="#BDBDBD"
          />
          <path
            d="M35 30H62.998C63.55 30.036 64 29.552 64 29C64 13.01 50.99 0 35 0C34.7348 0 34.4804 0.105357 34.2929 0.292893C34.1054 0.48043 34 0.734784 34 1V29.036C34 29.588 34.448 30.036 35 30ZM36 2.018C50.126 2.536 61.518 13.958 61.988 28.036H36V2.018Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_2897_1323">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Text className="text-center text-gray-200 w-[300px]">
        Responsdata verschijnt hier zodra u API-aanroepen begint te maken.
      </Text>
    </div>
  );

  const NoDataAPIUsage = () => (
    <div className="flex flex-col items-center justify-center h-full min-h-[300px] gap-2">
      <svg
        width="65"
        height="64"
        viewBox="0 0 65 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1667 16.001C11.1667 20.4196 20.7187 24.001 32.5001 24.001C44.2814 24.001 53.8334 20.4196 53.8334 16.001C53.8334 11.5823 44.2814 8.00098 32.5001 8.00098C20.7187 8.00098 11.1667 11.5823 11.1667 16.001Z"
          stroke="#BDBDBD"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.1667 16.001V32.001C11.1667 35.8143 18.2867 39.0063 27.8147 39.8063M53.8334 28.001V16.001"
          stroke="#BDBDBD"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.1667 32.001V48.001C11.1667 52.1236 19.4867 55.521 30.1747 55.953M47.9667 55.513L42.1747 58.5476C42.0029 58.6371 41.8095 58.677 41.6162 58.663C41.423 58.6489 41.2374 58.5814 41.0803 58.468C40.9232 58.3546 40.8007 58.1997 40.7265 58.0207C40.6523 57.8417 40.6293 57.6456 40.6601 57.4543L41.7667 51.025L37.0814 46.473C36.9416 46.3378 36.8425 46.166 36.7957 45.9772C36.7488 45.7884 36.7559 45.5903 36.8162 45.4054C36.8766 45.2205 36.9877 45.0562 37.1369 44.9314C37.2861 44.8067 37.4674 44.7263 37.6601 44.6996L44.1348 43.761L47.0307 37.913C47.1174 37.7388 47.2509 37.5922 47.4162 37.4898C47.5816 37.3874 47.7722 37.3331 47.9667 37.3331C48.1613 37.3331 48.3519 37.3874 48.5173 37.4898C48.6826 37.5922 48.8161 37.7388 48.9027 37.913L51.7987 43.761L58.2734 44.6996C58.4655 44.7273 58.6461 44.8081 58.7946 44.933C58.9432 45.0579 59.0538 45.2219 59.114 45.4064C59.1742 45.5909 59.1816 45.7886 59.1353 45.9771C59.089 46.1656 58.9909 46.3373 58.8521 46.473L54.1667 51.025L55.2707 57.4516C55.3039 57.6433 55.2826 57.8404 55.2094 58.0205C55.1361 58.2007 55.0138 58.3567 54.8563 58.4709C54.6989 58.585 54.5125 58.6527 54.3185 58.6663C54.1245 58.6799 53.9306 58.6388 53.7587 58.5476L47.9667 55.513Z"
          stroke="#BDBDBD"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <Text className="text-center text-gray-200 w-[300px]">
        Begin met het gebruik van onze API-diensten om uw verbruiksbijdrage te
        zien.
      </Text>
    </div>
  );
  return (
    <>
      <DashboardContainer pageTitle={"Analytics - Altum AI"}>
        <Header
          title={"Analytics"}
          subtitle={
            "De Analytics omgeving laat het verbruik zien o.b.v. de API key over de afgelopen 30 dagen. Dit bevat zowel het verbruik via het platform als eigen integraties in een applicatie omdat die van dezelfde API key gebruik maken."
          }
        />
        <div className="flex flex-col gap-8">
          <CardContainer
            title={"Algehele gebruik"}
            period={period}
            setPeriod={setPeriod}
            periods={periods}
          >
            {apiUsage.analytics.loading ? (
              <BarChartSkeleton />
            ) : apiData.length === 0 ? (
              <NoDataAPIUsage />
            ) : (
              <Suspense fallback={<BarChartSkeleton />}>
                <Chart data={apiData} />
              </Suspense>
            )}
          </CardContainer>
          <div className="flex gap-8 flex-col-reverse lg:flex-row">
            <CardContainer
              title={"API respons"}
              period={period}
              setPeriod={setPeriod}
              periods={periods}
            >
              {apiUsage.analytics.loading ? (
                <PieChatSkeleton />
              ) : Object.values(apiStatus).every((value) => value === 0) ? (
                <NoDataPieChart />
              ) : (
                <Suspense fallback={<PieChatSkeleton />}>
                  <PieChartComponent data={pieData} />
                </Suspense>
              )}
            </CardContainer>
            <CardContainer
              title={"Meest gebruikte API's"}
              period={period}
              setPeriod={setPeriod}
              periods={periods}
            >
              <div className="flex gap-4 flex-col p-4">
                {apiUsage.analytics.loading ? (
                  <UsedAPIListSkeleton />
                ) : topApis.length === 0 ? (
                  <NoDataAPIUsage />
                ) : (
                  topApis.map((apiData: any) => (
                    <div className="flex justify-between" key={apiData.apiName}>
                      <div className="flex gap-4 items-center">
                        <div
                          className={`w-2 h-2 rounded-full`}
                          style={{
                            backgroundColor: apiColorPicker(apiData.apiName),
                          }}
                        ></div>
                        <Text>{apiNameConverter(apiData.apiName)}</Text>
                      </div>
                      <Text>{apiData.usage}</Text>
                    </div>
                  ))
                )}
              </div>
            </CardContainer>
          </div>
          <APILogs logs={formattedLogs} />
        </div>
      </DashboardContainer>
    </>
  );
}

export default Analytics;
