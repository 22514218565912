import { InputProps } from "../../../../@types";

export const AvmFields: InputProps[] = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Waarderingsdatum",
    name: "valuation_date",
    type: "date",
    placeholder: "20191030 (JJJJMMDD)",
    tooltip:
      "De standaard waarderingsdatum is vandaag. Overschrijf door een datum in te sturen als YYYYMMDD",
  },
  {
    label: "Woonoppervlakte (m2)",
    name: "innersurfacearea",
    type: "number",
    placeholder: "100",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de modelwaarde berekening. Overschrijf de standaard waarde in de database",
  },
  {
    label: "Huidig energielabel",
    name: "energylabel",
    type: "slider",
    tooltip:
      "Het energielabel van de woning heeft invloed op de modelwaarde berekening. Overschrijf de standaard waarde in de database",
  },
  {
    label: "Perceeloppervlakte (m2)",
    name: "outersurfacearea",
    type: "number",
    placeholder: "150",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de modelwaarde berekening. Overschrijf de standaard waarde in de database",
  },
];

export const avmEndpoint = {
  title: "Woningwaarde+ API",
  description:
    "Plaats objectgegevens om een nauwkeurige geautomatiseerde modelwaardering via AVM+ te ontvangen",
  method: "POST",
  url: "https://api.altum.ai/avmplus",
  headers: [
    {
      name: "x-api-key",
      type: "string",
      required: true,
      description:
        "Unieke API-sleutel van Altum. Maak er een aan via het Mopsus(https://mopsus.altum.ai)",
    },
    {
      name: "Content-Type",
      type: "string",
      required: false,
      description: "application/json",
    },
  ],
  responses: [
    { status: 200, description: "Succesvolle reactie" },
    { status: 422, description: "Verkeerde invoer" },
    { status: 400, description: "Mislukte reactie" },
    { status: 429, description: "API key limiet overschreden" },
    { status: 500, description: "Service is niet beschikbaar en/of offline" },
    { status: 401, description: "Geen toegang" },
    { status: 403, description: "Verboden" },
  ],
};
