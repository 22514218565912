import { FC, useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";
import Subtitle from "../../../../components/Subtitle";
import { useAppDispatch } from "../../../../redux/hooks";
import { loadUser } from "../../../../redux/actions/authActions";

interface QRCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  qrCode: string;
  secret: string;
  onDone: () => void;
}

const QRCodeModal: FC<QRCodeModalProps> = ({
  isOpen,
  onClose,
  qrCode,
  secret,
  onDone,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleDone = async () => {
    try {
      await dispatch(loadUser({ shouldLoadExtras: false })).unwrap();
      onDone();
      onClose();
    } catch (error) {
      console.error("Error reloading user data:", error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-lg w-full max-w-[425px] relative animate-in fade-in zoom-in duration-200"
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-light hover:text-gray-dark transition-colors"
        >
          <MdClose size={20} />
        </button>

        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-dark mb-6">
            QR Code Scannen
          </h2>

          <div className="space-y-4">
            <div className="rounded-lg border border-gray-200 bg-white p-6">
              <Subtitle className="text-base mb-4 text-gray-dark text-center">
                Scan deze QR-code met uw authenticator-app
              </Subtitle>
              <img
                src={qrCode}
                alt="2FA QR Code"
                className="mx-auto w-48 h-48"
                onError={(e) =>
                  console.error("QR code image failed to load:", e)
                }
              />
              <div className="mt-4 px-4">
                <p className="text-sm text-gray-light text-center break-all">
                  Als u de QR-code niet kunt scannen, voer deze code dan
                  handmatig in:
                  <br />
                  <span className="mt-1 block font-mono bg-gray-50 p-2 rounded">
                    {secret}
                  </span>
                </p>
              </div>
            </div>

            <button
              className="w-full mt-4 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
              onClick={handleDone}
            >
              Klaar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;
