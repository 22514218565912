import { FC } from "react";
import { AvmFields } from "../data";
import DatePicker from "../../../../../components/DatePicker";
import { useFormContext } from "../../../usage-pages/components/FormContext";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { InputTypes } from "../../../../../components/ReturnInputField";
import { MultiValue, ActionMeta } from "react-select";

type Option = {
  label: string;
  value: string | number;
};

const IdentifyProperty: FC = () => {
  const { formValues, setFormValues } = useFormContext();

  const handleDateChange = (formattedDate: string) => {
    setFormValues((prev) => ({ ...prev, valuation_date: formattedDate }));
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | MultiValue<Option>
      | Option,
    actionMeta?: ActionMeta<Option>,
  ) => {
    if ("target" in event) {
      const { name, value, type } = event.target;
      setFormValues((prev) => ({
        ...prev,
        [name]: type === "number" ? parseFloat(value) || "" : value,
      }));
    } else if (Array.isArray(event)) {
      const name = actionMeta?.name;
      if (name) {
        setFormValues((prev) => ({
          ...prev,
          [name]: event,
        }));
      }
    } else if ("value" in event) {
      const name = actionMeta?.name;
      if (name) {
        setFormValues((prev) => ({
          ...prev,
          [name]: event.value,
        }));
      }
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {AvmFields.map((field, index) =>
        field.type === "date" ? (
          <DatePicker
            key={index}
            label={field.label}
            value={formValues[field.name]}
            className="w-full"
            onChange={handleDateChange}
          />
        ) : (
          <ReturnInputField
            key={index}
            name={field.name}
            label={field.label}
            type={field.type}
            options={field.options}
            placeholder={field.placeholder}
            value={formValues[field.name] || ""}
            onChange={handleInputChange}
          />
        ),
      )}
    </div>
  );
};

export default IdentifyProperty;
