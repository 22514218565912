import { FC, useEffect, useRef } from "react";
import { MdEmail, MdQrCode2, MdClose } from "react-icons/md";

interface TwoFactorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectMethod: (method: "email" | "authenticator") => void;
}

const TwoFactorModal: FC<TwoFactorModalProps> = ({
  isOpen,
  onClose,
  onSelectMethod,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-lg w-full max-w-[425px] relative animate-in fade-in zoom-in duration-200"
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-light hover:text-gray-dark transition-colors"
        >
          <MdClose size={20} />
        </button>

        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-dark mb-6">
            Kies 2FA-methode
          </h2>

          <div className="space-y-4">
            <div
              className="rounded-lg border border-gray-200 bg-white p-4 cursor-pointer hover:bg-secondary transition-colors"
              onClick={() => {
                onSelectMethod("authenticator");
                onClose();
              }}
            >
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-primary/10 p-2">
                  <MdQrCode2 className="h-6 w-6 text-primary" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-dark">
                    Authenticator-app
                  </h3>
                  <p className="text-sm text-gray-light">
                    Gebruik Google Authenticator of vergelijkbare apps
                  </p>
                </div>
              </div>
            </div>

            <div
              className="rounded-lg border border-gray-200 bg-white p-4 cursor-pointer hover:bg-secondary transition-colors"
              onClick={() => {
                onSelectMethod("email");
                onClose();
              }}
            >
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-primary/10 p-2">
                  <MdEmail className="h-6 w-6 text-primary" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-dark">E-mail</h3>
                  <p className="text-sm text-gray-light">
                    Ontvang verificatiecodes via e-mail
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorModal;
