import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { SetupIntentResult, StripeError } from "@stripe/stripe-js";
import { History } from "history";
import { FormEvent } from "react";
import { AsyncThunk } from "@reduxjs/toolkit";
import { CreateSubscriptionPayload, User } from "../../@types";
import {
  getMeteredPlanId,
  getMeteredTransactionId,
  getRecurringPlanId,
  getRecurringTransactionId,
  getRecurringUnlimitedId,
} from "../../helpers/stripeValues";
import { AppDispatch } from "../../redux/store";

type SubmitHandlerProps = {
  event: FormEvent<HTMLFormElement>;
  isCard: boolean;
  stripe: ReturnType<typeof useStripe>;
  elements: ReturnType<typeof useElements>;
  error: Error | null | StripeError;
  cardComplete: boolean;
  clientSecret: string | null;
  billingDetails: { name: string; email: string };
  user: User;
  history: History;
  createSubscription: AsyncThunk<
    boolean,
    {
      body: CreateSubscriptionPayload;
      dispatch: AppDispatch;
    },
    any
  >;
  updateSubscription: AsyncThunk<
    boolean,
    {
      body: CreateSubscriptionPayload;
      dispatch: AppDispatch;
    },
    any
  >;
  plan: string;
  status: string | null;
  setError: (error: Error | null | StripeError) => void;
  setProcessing: (processing: boolean) => void;
  dispatch: AppDispatch;
};
export const handleSubmit = async ({
  event,
  isCard,
  stripe,
  elements,
  error,
  cardComplete,
  clientSecret,
  billingDetails,
  user,
  history,
  createSubscription,
  updateSubscription,
  plan,
  status,
  setError,
  setProcessing,
  dispatch,
}: SubmitHandlerProps) => {
  event.preventDefault();

  setProcessing(true);

  if (isCard) {
    await handleCard({
      stripe,
      elements,
      error,
      cardComplete,
      clientSecret,
      billingDetails,
      user,
      history,
      createSubscription,
      updateSubscription,
      plan,
      status,
      setError,
      setProcessing,
      dispatch,
    });
  } else {
    await handleSepa({
      stripe,
      elements,
      clientSecret,
      billingDetails,
      user,
      history,
      createSubscription,
      updateSubscription,
      plan,
      status,
      setError,
      setProcessing,
      dispatch,
    });
  }
};

const handleCard = async ({
  stripe,
  elements,
  error,
  cardComplete,
  clientSecret,
  billingDetails,
  user,
  history,
  createSubscription,
  updateSubscription,
  plan,
  status,
  setError,
  setProcessing,
  dispatch,
}: Omit<SubmitHandlerProps, "event" | "isCard">) => {
  if (!stripe || !elements) {
    return;
  }
  if (error) {
    elements?.getElement("card")?.focus();
    return;
  }
  const card = elements.getElement(CardElement);

  const payload =
    clientSecret &&
    card &&
    (await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card,
        billing_details: billingDetails,
      },
    }));

  if (!payload) return;

  handlePayload({
    payload,
    user,
    history,
    createSubscription,
    updateSubscription,
    plan,
    status,
    setError,
    setProcessing,
    dispatch,
  });
};

const handleSepa = async ({
  stripe,
  elements,
  clientSecret,
  billingDetails,
  user,
  history,
  createSubscription,
  updateSubscription,
  plan,
  status,
  setError,
  setProcessing,
  dispatch,
}: Omit<SubmitHandlerProps, "event" | "isCard" | "cardComplete" | "error">) => {
  if (!stripe || !elements) {
    return;
  }
  const iban = elements.getElement(IbanElement);
  const payload =
    clientSecret &&
    iban &&
    (await stripe.confirmSepaDebitSetup(clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: { ...billingDetails },
      },
    }));
  if (!payload) return;

  handlePayload({
    payload,
    user,
    history,
    createSubscription,
    updateSubscription,
    plan,
    status,
    setError,
    setProcessing,
    dispatch,
  });
};

const handlePayload = ({
  payload,
  user,
  history,
  createSubscription,
  updateSubscription,
  plan,
  status,
  setError,
  setProcessing,
  dispatch,
}: Omit<
  SubmitHandlerProps,
  | "event"
  | "isCard"
  | "stripe"
  | "elements"
  | "cardComplete"
  | "clientSecret"
  | "billingDetails"
  | "error"
> & { payload: SetupIntentResult }) => {
  if (payload.error) {
    setError(payload.error);
    setProcessing(false);
    return;
  }
  if (
    payload.setupIntent.status === "succeeded" &&
    payload.setupIntent.payment_method
  ) {
    const body = {
      customerId: user.stripe_customer_id,
      paymentMethodId: payload.setupIntent.payment_method,
      apikey: plan.includes("Kadaster")
        ? user.transaction_api_key
        : user.api_key,
      meteredPriceId: plan.includes("Kadaster")
        ? getMeteredTransactionId(plan)
        : getMeteredPlanId(plan),
      recurringPriceId: plan.includes("Kadaster")
        ? getRecurringTransactionId(plan)
        : plan.includes("Altum AI") || plan.includes("Dagelijks")
        ? getRecurringPlanId(plan)
        : getRecurringUnlimitedId(plan),
    };
    if (status === "active") {
      dispatch(updateSubscription({ body, dispatch })).then(() => {
        history.push("/success");
        setProcessing(false);
      });
    } else {
      dispatch(createSubscription({ body, dispatch })).then(() => {
        history.push("/success");
        setProcessing(false);
      });
    }
  } else {
    history.push("/failed");
  }
};
