import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FcSearch } from "react-icons/fc";
import { VscTools } from "react-icons/vsc";
import { GiCheckMark } from "react-icons/gi";
import { FiPlus, FiMinus } from "react-icons/fi";
import { IoSparklesOutline } from "react-icons/io5";

import DashboardContainer from "../Dashboard/components/Container";
import Button from "../../components/Button";
import Subtitle from "../../components/Subtitle";
import Text from "../../components/Text";
import Title from "../../components/TItle";
const features = [
  "Bespaar uren schrijftijd",
  "Vergroot de betrokkenheid van potentiële kopers",
  "Onderscheid uzelf in een concurrerende markt",
  "Benadruk effectief de belangrijkste verkooppunten",
  "Produceer consistent hoogwaardige beschrijvingen",
];

const faqs = [
  {
    question: "Wat is de Property Description Generator?",
    answer:
      "De Property Description Generator is een tool ontworpen om gebruikers te helpen snel en eenvoudig aantrekkelijke en op maat gemaakte vastgoedomschrijvingen te maken. Het maakt gebruik van AI om beschrijvingen te genereren op basis van door de gebruiker ingevoerde gegevens, zoals afbeeldingen, vastgoeddetails en aanvullende opmerkingen.",
  },
  {
    question: "Hoe gebruik ik de Property Description Generator?",
    answer:
      "Je kunt de tool gebruiken door een afbeelding van het pand te uploaden, de invoervelden in te vullen (zoals locatie, oppervlakte en type pand) of extra opmerkingen toe te voegen. De tool kan een beschrijving genereren op basis van elk van deze invoeren, individueel of gecombineerd.",
  },
  {
    question:
      "Kan ik een vastgoedomschrijving genereren met alleen een afbeelding?",
    answer:
      "Ja, je kunt een vastgoedomschrijving genereren door simpelweg een afbeelding te uploaden. De tool is ontworpen om een gedetailleerde beschrijving te geven op basis van uitsluitend de visuele elementen van de vastgoedafbeelding.",
  },
  {
    question:
      "Wat als ik alleen de invoervelden wil invullen zonder een afbeelding te uploaden?",
    answer:
      "Dat is helemaal prima! Je kunt zoveel of zo weinig invoervelden invullen als je wilt, en de Property Description Generator zal een beschrijving maken op basis van de informatie die je verstrekt.",
  },
  {
    question:
      "Is het mogelijk om aangepaste details toe te voegen aan de vastgoedomschrijving?",
    answer:
      'Ja, je kunt het veld "Aanvullende opmerkingen" gebruiken om specifieke informatie of voorkeuren toe te voegen. De AI zal deze opmerkingen verwerken in de uiteindelijke beschrijving, zodat je meer controle hebt over het resultaat.',
  },
  {
    question: "Moet ik alle velden invullen om een beschrijving te krijgen?",
    answer:
      "Nee, je kunt zoveel of zo weinig velden invullen als je wilt. De tool is ontworpen om te werken met elke combinatie van invoeren, of het nu alleen een afbeelding, alleen de velden of beide is.",
  },
  {
    question: "Welke soorten vastgoed kan de tool beschrijven?",
    answer:
      "De Property Description Generator kan een breed scala aan vastgoed beschrijven, waaronder woningen, commerciële, industriële en gemengde panden. Je kunt het type vastgoed specificeren in de invoervelden.",
  },
  {
    question: "Kan ik de toon van de beschrijving kiezen?",
    answer:
      "Ja, je kunt de toon van de beschrijving selecteren. De beschikbare tonen zijn professioneel, vriendelijk en enthousiast, waardoor je de stijl kunt afstemmen op je doelgroep.",
  },
  {
    question:
      "In welke talen kan de Property Description Generator beschrijvingen genereren?",
    answer:
      "De tool ondersteunt momenteel zowel Engels als Nederlands voor het genereren van vastgoedomschrijvingen.",
  },
  {
    question: "Is er een woordlimiet voor de gegenereerde beschrijvingen?",
    answer:
      'Je kunt de lengte van de beschrijving specificeren (kort, gemiddeld of lang) in het veld "Lengte van de beschrijving". Dit stelt je in staat te bepalen hoe gedetailleerd de beschrijving zal zijn.',
  },
  {
    question: "Kan ik deze tool gratis gebruiken?",
    answer:
      "Ja, de tool is volledig gratis te gebruiken. Je kunt vastgoedomschrijvingen genereren zonder kosten.",
  },
  {
    question: "Hoe nauwkeurig zijn de gegenereerde vastgoedomschrijvingen?",
    answer:
      "De beschrijvingen worden gegenereerd met behulp van geavanceerde AI-algoritmes die de verstrekte invoer analyseren. Hoewel ze zeer nauwkeurig zijn, kunnen gebruikers de output verder verfijnen door gedetailleerde informatie in te voeren in de invoervelden of aanvullende opmerkingen.",
  },
  {
    question:
      "Wat is het voordeel van het toevoegen van afbeeldingen naast vastgoedgegevens?",
    answer:
      "Het toevoegen van afbeeldingen helpt de AI om levendigere en nauwkeurigere beschrijvingen te creëren door visuele details te analyseren die mogelijk niet worden vastgelegd door alleen tekst. Dit kan de aantrekkingskracht en nauwkeurigheid van de gegenereerde beschrijvingen verbeteren.",
  },
  {
    question: "Wat gebeurt er met de informatie die ik in de tool invoer?",
    answer:
      "Alle gegevens die je invoert, worden uitsluitend gebruikt voor het genereren van de vastgoedomschrijving. Raadpleeg het privacybeleid van het platform voor meer informatie over het gebruik en de opslag van gegevens.",
  },
];

const PropertyDescriptionGenerator: React.FC = () => {
  const [openFaqs, setOpenFaqs] = useState<boolean[]>(
    new Array(faqs.length).fill(false),
  );

  const toggleFAQ = (index: number) => {
    setOpenFaqs(openFaqs.map((item, i) => (i === index ? !item : item)));
  };

  return (
    <DashboardContainer pageTitle={"Property Description - Altum AI"}>
      <div className="flex flex-col justify-center items-center md:pl-4 md:pr-8 p-4 w-full space-y-16">
        <div className="flex flex-col gap-2 justify-center items-center">
          <div className="flex gap-2 items-center bg-gradient-to-r from-purple-500 to-green-500 text-transparent bg-clip-text">
            <Text className="">Meet</Text>
            <IoSparklesOutline size={15} className="text-purple-500" />
          </div>

          <Title>Woningbeschrijving Generator</Title>

          <Text className="text-gray-light text-center	">
            Welkom bij de Woningbeschrijving Generator, hét ultieme hulpmiddel
            voor makelaars om in enkele seconden aantrekkelijke en effectieve
            woningbeschrijvingen te maken
          </Text>
        </div>

        <Button className="w-full md:w-[360px] bg-[#BB6BD9] text-white">
          <Link to="/property-description/generate">Genereer beschrijving</Link>
        </Button>
        <div>
          <img
            src="/images/empty-image.svg"
            alt="Landing Page"
            className="w-full max-w-3xl mx-auto"
          />
        </div>
        <div className="flex flex-col gap-6 justify-center items-center w-full">
          <Subtitle className="text-xl font-bold text-center">
            Verbeter uw woningadvertenties met AI
          </Subtitle>
          <div className="flex gap-4 flex-col md:flex-row">
            <div className="flex flex-col gap-4 w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-lg justify-center items-center">
              <div className="text-[#0888ff] bg-[#e1f0fe] rounded-full w-10 h-10 text-center p-2.5">
                <FcSearch size={20} />
              </div>
              <Subtitle className="text-base">Wat doet het?</Subtitle>
              <Text className="text-gray-light text-center">
                Onze AI-gestuurde generator analyseert woninggegevens en foto's
                om unieke, professionele beschrijvingen te maken die de beste
                kenmerken van elke woning benadrukken.
              </Text>
            </div>
            <div className="flex flex-col gap-4 w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-lg justify-center items-center">
              <div className="text-[#F2545B] bg-[#FCDDDE] rounded-full w-10 h-10 text-center p-2.5">
                <VscTools size={20} />
              </div>
              <Subtitle className="text-base">Voor wie is het?</Subtitle>
              <Text className="text-gray-light text-center">
                Speciaal ontwikkeld voor makelaars en vastgoedbeheerders die
                tijd willen besparen en de kwaliteit van hun woningadvertenties
                willen verbeteren.
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6 justify-center items-center w-full">
          <Subtitle className="text-xl font-bold text-center">
            Waarom de Woningbeschrijving Generator gebruiken
          </Subtitle>
          <div className="flex flex-col gap-4 w-full md:p-8 p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-lg justify-center items-center grid grid-cols-1 md:grid-cols-2">
            {features.map((feature, index) => (
              <div key={index} className="flex gap-2 items-center">
                <GiCheckMark size={15} /> <Text>{feature}</Text>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-6 justify-center items-center w-full">
          <Subtitle className="text-xl font-bold">Veelgestelde vragen</Subtitle>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-lg">
            {faqs.map((faq, index) => (
              <div key={index} className="flex gap-2">
                <span className="mt-2">
                  {openFaqs[index] ? (
                    <FiMinus
                      size={15}
                      className="text-purple-600 cursor-pointer"
                      onClick={() => toggleFAQ(index)}
                    />
                  ) : (
                    <FiPlus
                      size={15}
                      className="text-purple-600 cursor-pointer"
                      onClick={() => toggleFAQ(index)}
                    />
                  )}
                </span>
                <div className="flex flex-col gap-2">
                  <Subtitle className="text-base">{faq.question}</Subtitle>
                  {openFaqs[index] && (
                    <Text className="text-gray-light">{faq.answer}</Text>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default PropertyDescriptionGenerator;
