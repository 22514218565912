import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsage } from "./apiUsage";
import addGAEvent from "../../helpers/addGAEvent";
import { getAllUnreadMessagesUser } from "./messageActions";
import { User } from "../../@types";
import { AppDispatch } from "../store";

export interface SignUpPayload {
  message: string;
  id: string;
  active: boolean;
  accountInitialised: boolean;
  status: string;
}
export interface SignInPayload {
  token: string;
  user: User;
}

interface LoadUserPayload {
  status: string;
  user: User;
}
interface FormData {
  email: string;
  password: string;
}

export interface TwoFARequiredResponse {
  status: string;
  message: string;
  userId: string;
  type: "email" | "authenticator";
}

export const signUp = createAsyncThunk<string, FormData>(
  "auth/signUp",
  async (formData, thunkApi) => {
    try {
      const data = {
        first_name: "",
        last_name: "",
        email: formData.email,
        password: formData.password,
      };
      const config = { headers: { "Content-Type": "application/json" } };

      const response = await axios.post<SignUpPayload>(
        "/api/v1/users/signup",
        data,
        config,
      );
      addGAEvent("User", "Account created");

      const tagBody = { event: "Signup", userId: formData.email };
      await axios.post("/api/v1/mopsus/tag-event", tagBody, config);
      toast.info(response.data.message);
      if (response.data.id) {
      }
      return response.data.id;
    } catch (error: any) {
      toast.error(error.response?.data.message);
      return thunkApi.rejectWithValue(error.response?.data.message);
    }
  },
);

export const signIn = createAsyncThunk<
  SignInPayload | TwoFARequiredResponse,
  { formData: Omit<FormData, "name">; dispatch: AppDispatch }
>("auth/signIn", async ({ formData, dispatch }, thunkApi) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const data = {
      email: formData.email,
      password: formData.password,
    };
    const res = await axios.post<SignInPayload | TwoFARequiredResponse>(
      "/api/v1/users/signin",
      data,
      config,
    );
    addGAEvent("User", "Log In");

    const tagBody = { event: "Login", userId: formData.email };
    await axios.post("/api/v1/mopsus/tag-event", tagBody, config);

    dispatch(loadUser());
    return res.data;
  } catch (error: any) {
    const errors = error.response?.data.message || error.message;
    toast.error(errors);
    return thunkApi.rejectWithValue(errors);
  }
});

export const loadUser = createAsyncThunk<
  User,
  { shouldLoadExtras?: boolean } | undefined
>("auth/loadUser", async (options = { shouldLoadExtras: true }, thunkApi) => {
  try {
    // Add throttling to prevent excessive calls
    const now = Date.now();
    const lastLoadTime = localStorage.getItem("lastLoadUserTime");

    // If we've called this function in the last second, don't make another API call
    if (lastLoadTime && now - parseInt(lastLoadTime) < 1000) {
      // Return a rejected promise to prevent state updates
      return thunkApi.rejectWithValue("Throttled loadUser call");
    }

    // Update the last load time
    localStorage.setItem("lastLoadUserTime", now.toString());

    axios.defaults.withCredentials = true;
    const res = await axios.get<LoadUserPayload>("/api/v1/users");
    const userId = res.data.user.user_id;

    // Only trigger these additional actions if explicitly requested
    // This helps prevent infinite update loops
    if (options.shouldLoadExtras) {
      thunkApi.dispatch(getUsage());
      thunkApi.dispatch(getAllUnreadMessagesUser({ userId }));
    }

    return res.data.user;
  } catch (error: any) {
    const errors = error.response?.data.message;
    return thunkApi.rejectWithValue(errors);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  window.localStorage.removeItem("profile");
  window.localStorage.removeItem("profileToken");

  const res = await axios.get("/api/v1/users/logout");
  return res.data;
});

export const verifyAccount = createAsyncThunk<boolean, string>(
  "auth/verify",
  async (userId: string, { rejectWithValue }) => {
    try {
      await axios.patch(`/api/v1/users/account-verification/${userId}`);
      toast.success("Succesvol aangemeld");
      return true;
    } catch (error) {
      return rejectWithValue(false);
    }
  },
);

export const verifyEmail = createAsyncThunk(
  "auth/verify-email",
  async ({ email, otp }: { email: string; otp: string }, thunkApi) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const data = { email: email, code: otp };
      const res = await axios.post<SignInPayload>(
        `/api/v1/users/verify-email`,
        data,
        config,
      );
      toast.success("Succesvol aangemeld");
      return { token: res.data.token, user: res.data.user };
    } catch (error: any) {
      toast.error(error.response?.data.message);
      throw new Error(error);
    }
  },
);

export const resendCode = createAsyncThunk(
  "auth/resendCode",
  async (email: string, thunkApi) => {
    try {
      const res = await axios.post<{ message: string }>(
        "/api/v1/users/send-verification-email",
        {
          email: email,
        },
      );
      toast.success(res.data.message);
      return res;
    } catch (error: any) {
      toast.error(error.response?.data.message);
      throw new Error(error);
    }
  },
);

export const allowSignupConfirmation = createAsyncThunk(
  "auth/allowSignupConfirm",
  async (userId: string) => {
    try {
      await axios.get(`/api/v1/users/allow-signup-confirmation/${userId}`);
      return true;
    } catch (error) {
      return false;
    }
  },
);

export const verify2FA = createAsyncThunk<
  SignInPayload,
  { userId: string; code: string; type?: "email" | "authenticator" }
>("auth/verify2FA", async ({ userId, code }) => {
  try {
    const response = await axios.post<SignInPayload>("/api/v1/2fa/verify", {
      userId,
      code,
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message || "Verificatie mislukt");
    throw error;
  }
});

export const request2FACode = createAsyncThunk<void, string>(
  "auth/request2FACode",
  async (userId) => {
    try {
      await axios.post("/api/v1/2fa/request-code", { userId });
      toast.success("New code sent to your email");
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Failed to send new code");
      throw error;
    }
  },
);
