import React, { ReactNode } from "react";
import ApiFormContainer from "../../components/ApiFormContainer";
import { AVMPlus } from "../../../../../assets/images/api/APIimages";

const progress = ["Woning identificatie", "Resultaat"];

const AVMContainer: React.FC<{
  children: ReactNode;
  page: number;
}> = ({ children, page }) => {
  return (
    <ApiFormContainer
      page={page}
      title="Woningwaarde+ API"
      subtitle="Waardeer een woning met de grootste dekking en hoogste accuratesse - De Woningwaarde+ API levert de transactiewaarde van de ingevoerde Nederlandse woning op de opgegeven taxatiedatum."
      progress={progress}
      resultSelector={(state) => state.avmPlus.result}
      link="https://docs.altum.ai/apis/woningwaarde+-api"
      image={AVMPlus}
    >
      {children}
    </ApiFormContainer>
  );
};

export default AVMContainer;
