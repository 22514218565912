import createApiSlice from "../../helpers/createApiSlice";
import {
  listingPriceOneTimeUse,
  postListingPriceDetails,
} from "../actions/listingPriceActions";
import { ListingPriceResult } from "../../pages/Api/usage-pages/listingprice/types";

const listingPriceSlice = createApiSlice<ListingPriceResult>(
  "listingprice",
  postListingPriceDetails,
  listingPriceOneTimeUse,
);

export const { clearResults, modifyQueries } = listingPriceSlice.actions;

export default listingPriceSlice.reducer;
