import ApiCard from "./ApiCard";
import { APIdata } from "./ApisData";
import { FC } from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectAllowedApis } from "../../redux/features/apiUsageSlice";

// Map API titles to their corresponding AWS API names
const apiNameMapping: { [key: string]: string } = {
  "Verduurzaming advies": "Sustainability",
  "Kadaster transacties": "CreditAPI",
  "Woning referenties opvragen": "Interactive Reference",
  "Woningwaarde API": "avm",
  "Woningwaarde+ API": "AVM+",
  "Vraagprijs API": "listingprice",
  "Locatie-gegevens": "Location-Data",
  "Object geometrie opvragen": "Geometry",
  "Woningkenmerken API": "ObjectData",
  "Foto labelen": "Label API",
  "Conditie score voor badkamer en keuken": "Condition-Score",
  Verhuisdata: "move-data",
  "WOZ waarde": "woz",
  "NTA 8800 Energy label API": "Energy Label API",
  "Energielabel inzichten API": "EnergyLabelInsights",
  "Zonnepanelen dakenscan": "Solar API",
  "Energie & klimaat API": "EnergyClimateAPI",
  Voorzieningen: "amenities",
  "Huurreferentie API": "Rental-Reference-API",
  "Autosuggestie API": "autosuggest",
  "Herbouwwaarde API": "Rebuild",
  "WWS Punten API": "WWSPoints",
};

type Props = {
  activeCategory: string;
  search: string;
};

const Api: FC<Props> = ({ activeCategory, search }) => {
  const allowedApis = useAppSelector((state) => state.apiUsage.allowedApis);

  const filteredData = APIdata.filter((data) => {
    // Check if the API is allowed based on the mapping
    const apiName = apiNameMapping[data.title];
    const isAllowed = allowedApis.includes(apiName);
    // Apply category and search filters
    const matchesCategory =
      activeCategory === "Alle producten" || data.category === activeCategory;
    const matchesSearch = data.title
      .toLowerCase()
      .includes(search.toLowerCase());

    return isAllowed && matchesCategory && matchesSearch;
  });

  return (
    <>
      {filteredData?.map((item, index) => (
        <ApiCard
          key={index}
          title={item.title}
          text={item.text}
          image={item.img}
          link={item.link}
          category={item.category}
          isNew={item.isNew}
        />
      ))}
    </>
  );
};

export default Api;
