import { FC, useCallback, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  verify2FA,
  request2FACode,
  loadUser,
} from "../../../../redux/actions/authActions";
import AuthForm from "../../components/AuthForm";
import AuthContainer from "../../components/AuthContainer";
import AuthHeader from "../../components/AuthHeader";
import Text from "../../../../components/Text";
import OTPComponent from "../../../../components/OTPComponent";

interface TwoFactorVerifyProps {
  userId: string;
  twoFactorType: "email" | "authenticator";
  onVerifySuccess: () => void;
}

const TwoFactorVerify: FC<TwoFactorVerifyProps> = ({
  userId,
  twoFactorType,
  onVerifySuccess,
}) => {
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();

  const verifyCode = useCallback(
    async (code: string) => {
      if (!code || code.length !== 6) {
        setError("Voer een geldige verificatiecode in");
        return;
      }

      if (loading) return; // Prevent multiple submissions

      setError("");
      setLoading(true);

      try {
        const result = await dispatch(verify2FA({ userId, code })).unwrap();
        // Clear any dashboard redirection block from localStorage
        localStorage.removeItem("dashboardRedirected");

        // If we have valid authentication data, update the state and redirect
        if (result && result.token && result.user) {
          try {
            // Load fresh user data and wait for it to complete
            await dispatch(loadUser({ shouldLoadExtras: true })).unwrap();

            // Now that state is updated, trigger success callback
            onVerifySuccess();
          } catch (err) {
            setError("Failed to load user data. Please try again.");
          }
        } else {
          setError(
            "Verificatie geslaagd, maar kon niet inloggen. Probeer opnieuw.",
          );
        }
      } catch (err: any) {
        setError(err.response?.data?.message || "Verificatie mislukt");
        setOtpCode(""); // Clear code on error
      } finally {
        setLoading(false);
      }
    },
    [userId, onVerifySuccess, dispatch, loading],
  );

  const handleVerify = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault();
      await verifyCode(otpCode);
    },
    [otpCode, verifyCode],
  );

  const handleResendCode = useCallback(async () => {
    if (twoFactorType !== "email") return;

    try {
      await dispatch(request2FACode(userId));
      setError("Er is een nieuwe code naar uw e-mail verzonden");
      setOtpCode(""); // Clear previous code
    } catch (err: any) {
      setError("Kon geen nieuwe code verzenden. Probeer het opnieuw.");
    }
  }, [twoFactorType, userId, dispatch]);

  const handleOTPChange = (value: string) => {
    setError("");
    setOtpCode(value);
    if (value.length === 6) {
      verifyCode(value); // Use the new value directly instead of state
    }
  };

  return (
    <AuthContainer>
      <AuthHeader
        title="Twee-factor authenticatie"
        description={
          twoFactorType === "email"
            ? "Voer de code in die naar uw e-mail is verzonden"
            : "Voer de code in van uw authenticator-app"
        }
      />

      <AuthForm onSubmit={handleVerify} isLoading={loading} isError={!!error}>
        <div className="mt-6">
          <OTPComponent
            length={6}
            onChangeOTP={handleOTPChange}
            autoFocus
            disabled={loading}
          />

          {error && (
            <Text className="text-red-500 text-sm text-center mt-2">
              {error}
            </Text>
          )}
        </div>

        {twoFactorType === "email" && (
          <Text className="text-center mt-4">
            <button
              type="button"
              onClick={handleResendCode}
              className="text-green-600 hover:underline"
              disabled={loading}
            >
              Code opnieuw versturen
            </button>
          </Text>
        )}
      </AuthForm>
    </AuthContainer>
  );
};

export default TwoFactorVerify;
