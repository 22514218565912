import { InputProps } from "../../../../@types";

export const AvmFields: InputProps[] = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Waarderingsdatum",
    name: "valuationdate",
    type: "date",
    placeholder: "20191030 (JJJJMMDD)",
    tooltip:
      "De standaard waarderingsdatum is vandaag. Overschrijf door een datum in te sturen als YYYYMMDD",
  },
  {
    label: "Bouwjaar",
    name: "buildyear",
    type: "number",
    placeholder: "2000",
    tooltip: "Jaar van constructie",
  },
  {
    label: "Woonoppervlakte (m2)",
    name: "innersurfacearea",
    type: "number",
    placeholder: "100",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de modelwaarde berekening",
  },
  {
    label: "Perceeloppervlakte (m2)",
    name: "outersurfacearea",
    type: "number",
    placeholder: "150",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de modelwaarde berekening",
  },
  {
    label: "Huidig energielabel",
    name: "energylabel",
    type: "slider",
    tooltip:
      "Het energielabel van de woning heeft invloed op de modelwaarde berekening",
  },
  {
    label: "Woningtype",
    name: "housetype",
    type: "select",
    placeholder: "Vrijstaande woning",
    options: [
      { value: "Vrijstaande woning", label: "Vrijstaande woning" },
      {
        value: "Vrijstaande doelgroepwoning",
        label: "Vrijstaande doelgroepwoning",
      },
      {
        value: "Vrijstaande recreatiewoning",
        label: "Vrijstaande recreatiewoning",
      },
      { value: "2 onder 1 kap woning", label: "2 onder 1 kap woning" },
      {
        value: "Geschakelde 2 onder 1 kapwoning",
        label: "Geschakelde 2 onder 1 kapwoning",
      },
      { value: "Geschakelde woning", label: "Geschakelde woning" },
      { value: "Tussen/rijwoning", label: "Tussen/rijwoning" },
      {
        value: "Tussen/rij doelgroepwoning",
        label: "Tussen/rij doelgroepwoning",
      },
      { value: "Hoekwoning", label: "Hoekwoning" },
      { value: "Eindwoning", label: "Eindwoning" },
      { value: "Galerijflat", label: "Galerijflat" },
      { value: "Portiekflat", label: "Portiekflat" },
      { value: "Corridorflat", label: "Corridorflat" },
      { value: "Maisonnette", label: "Maisonnette" },
      { value: "Benedenwoning", label: "Benedenwoning" },
      { value: "Benedenwoning", label: "Bovenwoning" },
      { value: "Portiekwoning", label: "Portiekwoning" },
    ],
    tooltip: "Woningtype van het object",
  },
];

export const avmEndpoint = {
  title: "Woningwaarde API",
  description:
    "Met dit eindpunt kunt u antwoord ontvangen van het Altum AI AVM-model.",
  method: "POST",
  url: "https://api.altum.ai/avm",
  headers: [
    {
      name: "x-api-key",
      type: "string",
      required: true,
      description:
        "Unieke API-sleutel van Altum. Maak er een aan via het Mopsus(https://mopsus.altum.ai)",
    },
    {
      name: "Content-Type",
      type: "string",
      required: false,
      description: "application/json",
    },
  ],
  responses: [
    { status: 200, description: "Succesvolle reactie" },
    { status: 400, description: "Mislukte reactie" },
    { status: 401, description: "Geen toegang" },
    { status: 403, description: "Verboden" },
    { status: 500, description: "Service is niet beschikbaar en/of offline" },
    { status: 422, description: "Verkeerde invoer" },
    { status: 429, description: "API key limiet overschreden" },
  ],
};
