import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { AppDispatch } from "../store";

export interface ApiUsageState {
  usage: number;
  usagePlan: UsagePlan;
  plan?: string;
  totalCredits?: string | number;
  remaining?: number;
  used?: number;
  subscription?: boolean;
  allowedApiNames?: string[];

  usageToday: number;
  usageYesterday: number;
  usageLastWeek: number;
  usageThisWeek: number;

  transactionUsage?: number;
  transactionSubscription?: boolean;
  transactionUsageToday?: number;
  transactionUsageYesterday?: number;
  transactionUsageThisWeek?: number;
  transactionUsageLastWeek?: number;
}

interface UsagePlan {
  name: string;
  quota: number | null;
}
interface TotalUsage {
  status: string;
  sumApiCalls: number;
}
export interface AnalyticsState {
  statistics: {
    day: string;
    calls: number;
    AmenitiesAPI: number;
    AutoSuggestAPI: number;
    AVMAPI: number;
    EcoValue: number;
    LocationAPI: number;
    MoveDataAPI: number;
    WOZAPI: number;
    BagAPI: number;
    ReferenceAPI: number;
    SustainabilityAPI: number;
    "InteractiveReference-API": number;
    LabellingAPI: number;
    TransactionAPI: number;
    ObjectDataAPI: number;
    EnergyLabelAPI: number;
    ObjectGeometryAPI: number;
  };
  responseStatus: {
    "400": number;
    "422": number;
    "429": number;
    "500": number;
    success: number;
  };
}

interface AnanlyticsConfig extends AxiosRequestConfig {
  headers: { "x-api-key": string; "x-api-key-2"?: string };
}

export const getUsage = createAsyncThunk(
  "apiUsage/getUsage",
  async (_, thunkApi) => {
    // Implement throttling to prevent rapid successive calls
    const lastUsageCallTime = localStorage.getItem("lastApiUsageCall");
    const currentTime = Date.now();

    // Only allow this call once every 3 seconds to prevent update loops
    if (lastUsageCallTime && currentTime - parseInt(lastUsageCallTime) < 3000) {
      console.log("Throttling getUsage action to prevent update loops");
      return thunkApi.rejectWithValue(
        "Action throttled to prevent infinite loops",
      );
    }

    // Set the timestamp for this call
    localStorage.setItem("lastApiUsageCall", currentTime.toString());

    try {
      const res = await axios.get<ApiUsageState>("/api/v1/aws/usage");
      const { data } = res;

      if (data.usagePlan.name !== "Platform - Pay per use") {
        const total = data.usagePlan.quota;
        const used = data.usage;
        let remaining;
        if (total && typeof total === "number" && typeof used === "number") {
          remaining = total - used;
        } else remaining = "-";
        return {
          plan: data.usagePlan.name,
          totalCredits: total,
          remaining,
          used,
          usageToday: data.usageToday,
          usageYesterday: data.usageYesterday,
          usageThisWeek: data.usageThisWeek,
          usageLastWeek: data.usageLastWeek,
          transactionUsed: data.transactionUsage,
          subscription: data.subscription,
          transactionSubscription: data.transactionSubscription,
          transactionUsageToday: data.transactionUsageToday,
          transactionUsageYesterday: data.transactionUsageYesterday,
          transactionUsageThisWeek: data.transactionUsageThisWeek,
          transactionUsageLastWeek: data.transactionUsageLastWeek,
          allowedApiNames: data.allowedApiNames,
        };
      }
      const total = "onbeperkt";
      const used = data.usage;
      return {
        plan: data.usagePlan.name,
        totalCredits: total,
        remaining: "-",
        used,
        usageToday: data.usageToday,
        usageYesterday: data.usageYesterday,
        usageThisWeek: data.usageThisWeek,
        usageLastWeek: data.usageLastWeek,
        transactionUsed: data.transactionUsage,
        subscription: data.subscription,
        transactionSubscription: data.transactionSubscription,
        transactionUsageToday: data.transactionUsageToday,
        transactionUsageYesterday: data.transactionUsageYesterday,
        transactionUsageThisWeek: data.transactionUsageThisWeek,
        transactionUsageLastWeek: data.transactionUsageLastWeek,
        allowedApiNames: data.allowedApiNames,
      };
    } catch (error) {
      console.error("Error fetching API usage:", error);
      return thunkApi.rejectWithValue("Failed to fetch API usage");
    }
  },
);

// Async thunk to get APIs total calls
export const getApisTotalCalls = createAsyncThunk(
  "apiUsage/getApisTotalCalls",
  async () => {
    const res = await axios.get<TotalUsage>("/api/v1/aws/total-calls");
    return res.data.sumApiCalls;
  },
);

// Async thunk to get APIs analytics
export const getApisAnalytics = createAsyncThunk(
  "apiUsage/getApisAnalytics",
  async ({
    days,
    apiKey,
    transactionApiKey,
  }: {
    days: string;
    apiKey: string;
    transactionApiKey?: string;
  }) => {
    const options: AnanlyticsConfig = {
      method: "GET",
      url: "/api/v1/aws/usage-analytics/" + days,
      headers: { "x-api-key": apiKey, "x-api-key-2": transactionApiKey },
    };

    const response = await axios.request<AnalyticsState>(options);
    return response.data;
  },
);

export const getUsageLogs = createAsyncThunk(
  "apiUsage/getUsageLogs",
  async (_, thunkApi) => {
    try {
      // Add throttling to prevent rapid successive calls
      const lastLogsCallTime = localStorage.getItem("lastUsageLogsCall");
      const currentTime = Date.now();

      // Only allow this call once every 5 seconds
      if (lastLogsCallTime && currentTime - parseInt(lastLogsCallTime) < 5000) {
        return thunkApi.rejectWithValue(
          "Action throttled to prevent update loops",
        );
      }

      // Set the timestamp for this call
      localStorage.setItem("lastUsageLogsCall", currentTime.toString());

      const response = await axios.get<
        {
          api_name: string;
          requestTime: string;
          status: string;
        }[]
      >("/api/v1/aws/usage-logs");

      // If we got a successful response, clear the throttle timestamp
      // This allows immediate retry in case of failure
      localStorage.removeItem("lastUsageLogsCall");

      return response.data;
    } catch (error: any) {
      // In case of error, allow retry after 2 seconds
      setTimeout(() => {
        localStorage.removeItem("lastUsageLogsCall");
      }, 2000);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const clearApiUsageState = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "apiUsage/clearState" });
};
