import createApiSlice from "../../helpers/createApiSlice";
import {
  avmPlusOneTimeUse,
  postAvmPlusDetails,
} from "../actions/avmPlusActions";

const avmPlusSlice = createApiSlice(
  "avmplus",
  postAvmPlusDetails,
  avmPlusOneTimeUse,
);

export const { clearResults, modifyQueries } = avmPlusSlice.actions;

export default avmPlusSlice.reducer;
