import { useEffect, useState } from "react";
import GetstartedHeader from "./components/Header";
import OnboardingStep from "./components/OnboardingStep";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addOnboardingState,
  getOnboardingState,
} from "../../../redux/actions/onboardingActions";
import { Link, useHistory } from "react-router-dom";
import GetStartedSkeleton from "./components/GetStartedSkeleton";
import { Helmet } from "react-helmet";
import Button from "../../../components/Button";
import api from "../../../utils/api";

type ChecklistItemData = {
  id: string;
  label: string;
  completed: boolean;
  handler: (id: string) => void;
};

const logAnalyticsEvent = async (eventType: string) => {
  try {
    await api.post("/analytics/event", { event_type: eventType });
  } catch (error) {
    console.error("Failed to log analytics event:", error);
  }
};

const GetStarted = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { onboardingSteps, loading } = useAppSelector(
    (state) => state.onboarding,
  );
  const orderedStep = onboardingSteps
    ? [...onboardingSteps]
    : [].sort(
        (a: { stepRank: number }, b: { stepRank: number }) =>
          a.stepRank - b.stepRank,
      );
  const initialChecklistItems: ChecklistItemData[] = [
    {
      id: "active-email",
      label: "Bevestig het email adres",
      completed: false,
      handler(id) {
        //Handle onboarding modal
      },
    },
    {
      id: "onboarding-qs",
      label: "Welkomstenquête afronden",
      completed: false,
      async handler() {
        await logAnalyticsEvent("onboarding_questionnaire");
        history.push("/onboarding");
      },
    },
    {
      id: "find-api-key",
      label: "Vind jouw API-key",
      completed: false,
      async handler(id: string) {
        await logAnalyticsEvent("find_api_key");
        history.push("/apikeys");
        dispatch(addOnboardingState(id)).then(() => {
          dispatch(getOnboardingState());
        });
      },
    },
    {
      id: "request-data",
      label: "Voer je eerste API-call uit",
      completed: false,
      async handler(id: string) {
        await logAnalyticsEvent("first_api_call");
        history.push("/dashboard/products");
        dispatch(addOnboardingState(id)).then(() => {
          dispatch(getOnboardingState());
        });
      },
    },
    {
      id: "read-docs",
      label: "Lees onze API-documentatie",
      completed: false,
      async handler(id: string) {
        await logAnalyticsEvent("read_docs");
        window.open("https://docs.altum.ai/", "_blank");
        dispatch(addOnboardingState(id)).then(() => {
          dispatch(getOnboardingState());
        });
      },
    },
    {
      id: "book-demo",
      label: "Boek een video demo",
      completed: false,
      async handler(id: string) {
        await logAnalyticsEvent("book_demo");
        window.open("https://cal.com/altumai/demo", "_blank");
        dispatch(addOnboardingState(id))
          .then(() => {
            dispatch(getOnboardingState());
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  ];
  const updatedChecklist =
    onboardingSteps && updateChecklist(initialChecklistItems, orderedStep);

  const completedSteps =
    onboardingSteps &&
    [...onboardingSteps].filter(
      (step: { completed: boolean }) => step.completed === true,
    );
  useEffect(() => {
    dispatch(getOnboardingState());
  }, [dispatch]);

  const [hasSustainabilityMeasures, setHasSustainabilityMeasures] = useState<
    boolean | null
  >(null);

  const handleNext = () => {
    if (hasSustainabilityMeasures === true) {
      history.push("/dashboard/measures");
    } else {
      history.push("/dashboard/preferences");
    }
  };

  return (
    <>
      <Helmet>
        <title>Aan de slag - Altum AI</title>
      </Helmet>
      <div className="md:w-[500px] m-auto w-[340px]">
        <GetstartedHeader
          step={completedSteps?.length}
          title={"Welkom op het Altum AI-platform!"}
          description={
            "Altum AI is jouw vertrouwde partner voor de integratie van vastgoeddata. Enthousiast om aan de slag te gaan? Je krijgt elke maand 15 gratis credits om onze API's te verkennen. Voer deze stappen uit om aan de slag te gaan!"
          }
        />
        {loading || !onboardingSteps ? (
          <GetStartedSkeleton />
        ) : (
          <OnboardingStep steps={updatedChecklist} />
        )}
        <Button className="bg-primary w-full md:hidden">
          <Link to="/dashboard">Naar het dashboard</Link>
        </Button>
      </div>
      <div className="space-y-6">
        <h2>What is the status of the property?</h2>

        <div className="space-y-2">
          <p>Have any sustainability measures been implemented in your home?</p>
          <div className="flex gap-4">
            <label>
              <input
                type="radio"
                name="sustainabilityMeasures"
                value="yes"
                onChange={() => setHasSustainabilityMeasures(true)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="sustainabilityMeasures"
                value="no"
                onChange={() => setHasSustainabilityMeasures(false)}
              />
              No
            </label>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button onClick={() => history.goBack()}>Back</Button>
          <Button onClick={handleNext} className="bg-primary">
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default GetStarted;

function updateChecklist(
  initialChecklistItems: ChecklistItemData[],
  onboardingSteps: any,
) {
  const updateChecklistItem = initialChecklistItems.map((item) => {
    const step =
      onboardingSteps &&
      onboardingSteps.find(
        (step: { stepId: string; stepName: string; completed: boolean }) =>
          step.stepName === item.label,
      );
    return {
      ...step,
      handler: item.handler,
    };
  });
  return updateChecklistItem;
}
