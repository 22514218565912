import React, { useCallback, useEffect, useState } from "react";

interface DatePickerProps {
  /**
   * The current value formatted as "YYYYMMDD"
   */
  value?: string;
  /**
   * Callback fired when the date is changed.
   * The date is provided as a string in "YYYYMMDD" format.
   */
  onChange: (formattedDate: string) => void;
  /**
   * Label for the date input (optional)
   */
  label?: string;
  /**
   * Optional CSS class name
   */
  className?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  label,
  className,
}) => {
  // Convert YYYYMMDD to YYYY-MM-DD format for HTML5 date input
  const formatForInput = (dateStr?: string) => {
    if (!dateStr || dateStr.length !== 8) return "";
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return `${year}-${month}-${day}`;
  };

  // Convert YYYY-MM-DD to YYYYMMDD format
  const formatForOutput = (dateStr: string) => {
    return dateStr.replace(/-/g, "");
  };

  // Set initial value
  const [inputValue, setInputValue] = useState(formatForInput(value));

  // Update input value when prop changes
  useEffect(() => {
    setInputValue(formatForInput(value));
  }, [value]);

  // Handle date change
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newDate = e.target.value;
      setInputValue(newDate);

      if (newDate) {
        onChange(formatForOutput(newDate));
      }
    },
    [onChange],
  );

  // If no value is provided, default to today's date
  useEffect(() => {
    if (!value) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const formattedToday = `${year}${month}${day}`;
      onChange(formattedToday);
    }
  }, [value, onChange]);

  return (
    <div className={className}>
      {label && <label className="text-gray-light mb-2 block">{label}</label>}
      <input
        type="date"
        value={inputValue}
        onChange={handleChange}
        className="focus:border-0 focus:ring-0 focus:outline-primary border-gray-light w-full rounded-md"
        aria-label={label || "Date picker"}
      />
    </div>
  );
};

export default DatePicker;
