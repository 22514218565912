import React from "react";
import { Page, Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import { styles } from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";
import formatDate from "../../../../../helpers/convertToDutchMonth";

interface MarketValuePageProps {
  data: {
    price_estimation: string;
    valuation_date: string;
    confidence: string;
  };
}

const MarketValuePage: React.FC<MarketValuePageProps> = ({ data }) => {
  const formatPrice = (price: string) => {
    const numPrice = parseInt(price, 10);
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(numPrice);
  };

  const MoneyBagIcon = () => (
    <Svg width="15" height="15" viewBox="0 0 24 24">
      <Path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z"
        fill="#00A651"
      />
    </Svg>
  );

  const CalendarIcon = () => (
    <Svg width="15" height="15" viewBox="0 0 24 24">
      <Path
        d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"
        fill="#00A651"
      />
    </Svg>
  );

  const BadgeIcon = () => (
    <Svg width="15" height="15" viewBox="0 0 24 24">
      <Path
        d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-9 13H7v-4h4v4zm0-6H7V5h4v4zm6 6h-4v-4h4v4zm0-6h-4V5h4v4z"
        fill="#00A651"
      />
    </Svg>
  );

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.pageTitle}>Uw huis op de markt</Text>

      <Text style={styles.pageText}>
        Een uitgebreid overzicht van de huidige marktwaarde van uw eigendom,
        gebaseerd op geavanceerde gegevensmodelingen en regionale markttrends
      </Text>

      <View style={styles.investmentContainer}>
        <View style={styles.investmentItem}>
          <View style={styles.investmentIconContainer}>
            <MoneyBagIcon />
            <Text style={styles.investmentLabel}>Marktwaarde</Text>
          </View>
          <Text style={styles.investmentValue}>
            €{convertToEuFormat(data.price_estimation)}
          </Text>
        </View>

        <View style={styles.investmentItem}>
          <View style={styles.investmentIconContainer}>
            <CalendarIcon />
            <Text style={styles.investmentLabel}>Taxatiedatum</Text>
          </View>
          <Text style={styles.investmentValue}>
            {formatDate(data.valuation_date)}
          </Text>
        </View>

        <View style={styles.investmentItem}>
          <View style={styles.investmentIconContainer}>
            <BadgeIcon />
            <Text style={styles.investmentLabel}>
              Betrouwbaarheidspercentage
            </Text>
          </View>
          <Text style={styles.investmentValue}>
            {data.confidence
              ? `90% van ${formatPrice(
                  Math.ceil(parseFloat(data.price_estimation) * 0.9).toString(),
                )} tot ${formatPrice(
                  Math.ceil(parseFloat(data.price_estimation) * 1.1).toString(),
                )}`
              : "-"}
          </Text>
        </View>
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | 2024</Text>
        <Image src={altumLogo} style={styles.footerLogo} />
      </View>
    </Page>
  );
};

export default MarketValuePage;
