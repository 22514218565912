import { FC, useCallback, useState } from "react";
import axios from "axios";
import { Form } from "../../../../components/Form";
import {
  EmailSettings,
  FormField,
  TwoFactorResponse,
} from "../../../../@types";
import Switch from "../../../../components/Switch";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateUserEmailSettings } from "../../../../redux/actions/settingAction";
import Subtitle from "../../../../components/Subtitle";
import { RiMailSettingsLine } from "react-icons/ri";
import { LiaIndustrySolid } from "react-icons/lia";
import { IoWarningOutline } from "react-icons/io5";
import { MdSecurity } from "react-icons/md";
import { ChevronDown, ChevronUp } from "lucide-react";
import { loadUser } from "../../../../redux/actions/authActions";
import TwoFactorModal from "./TwoFactorModal";
import QRCodeModal from "./QRCodeModal";
import DisableTwoFactorModal from "./DisableTwoFactorModal";

const SettingItem: FC<{
  title: string;
  value: boolean;
  field:
    | "error_429"
    | "error_403"
    | "error_500"
    | "error_422"
    | "analytics_email_usage"
    | "invoice_successful"
    | "credit_depleted";
  user_id: string;
}> = ({ title, value, field, user_id }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="rounded-lg border border-gray-200 bg-white">
      <div className="flex justify-between items-center p-4">
        <Subtitle className="text-base text-gray-dark">{title}</Subtitle>
        <Switch
          check={value}
          onChange={() =>
            dispatch(
              updateUserEmailSettings({
                id: user_id,
                field,
                value: !value,
              }),
            )
          }
        />
      </div>
    </div>
  );
};

const Settings: FC<EmailSettings> = ({
  analytics_email_usage,
  invoice_successful,
  credit_depleted,
  error_403,
  error_500,
  error_429,
  error_422,
  user_id,
  two_factor_enabled,
  two_factor_type,
}) => {
  const [selected, setSelected] = useState<number | null>(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCode, setQRCode] = useState("");
  const [secret, setSecret] = useState("");
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const dispatch = useAppDispatch();

  const toggle = (i: number) => {
    setSelected(selected === i ? null : i);
  };

  const handleSubmit = useCallback(
    async (data: Record<string, string>) => {
      try {
        await axios.post(`/api/v1/users/${Object.keys(data)[0]}`, data);
        await dispatch(loadUser({ shouldLoadExtras: false })).unwrap();
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch],
  );

  const handleDisable2FA = async (password: string) => {
    try {
      await axios.post("/api/v1/2fa/disable", { password });
      setQRCode("");
      setSecret("");
      setShowQRCode(false);
      await dispatch(loadUser({ shouldLoadExtras: false })).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const handle2FAToggle = async () => {
    try {
      if (!two_factor_enabled) {
        setShowTwoFactorModal(true);
      } else {
        setShowDisableModal(true);
      }
    } catch (error: any) {
      console.error("Error toggling 2FA:", error);
    }
  };

  const handleMethodSelect = useCallback(
    async (method: "email" | "authenticator") => {
      try {
        const response = await axios.post<TwoFactorResponse>(
          "/api/v1/2fa/enable",
          {
            type: method,
          },
        );

        if (response.data.data?.qrCode) {
          setQRCode(response.data.data.qrCode);
          setSecret(response.data.data.secret || "");
          setShowQRCode(true);
        }

        setShowTwoFactorModal(false);
        if (method === "email") {
          await dispatch(loadUser({ shouldLoadExtras: false })).unwrap();
        }
      } catch (error) {
        console.error("Error enabling 2FA:", error);
      }
    },
    [dispatch],
  );

  const settings = [
    {
      title: "429: Te veel aanvragen e-mail",
      field: "error_429" as const,
      value: error_429,
    },
    {
      title: "500: Probleem met API e-mail",
      field: "error_500" as const,
      value: error_500,
    },
    {
      title: "403: Niet geautoriseerde e-mail",
      field: "error_403" as const,
      value: error_403,
    },
    {
      title: "422: Invoervalidatie mislukt e-mail",
      field: "error_422" as const,
      value: error_422,
    },
    {
      title: "Analytics e-mailgebruik",
      field: "analytics_email_usage" as const,
      value: analytics_email_usage,
    },
    {
      title: "Factuur succesvolle e-mail",
      field: "invoice_successful" as const,
      value: invoice_successful,
    },
    {
      title: "E-mail met tegoed opgebruikt",
      field: "credit_depleted" as const,
      value: credit_depleted,
    },
  ];

  return (
    <>
      <div className="mt-4">
        <div className="flex items-center mb-8">
          <RiMailSettingsLine size={20} className="mr-4 text-primary" />
          <Subtitle className="text-base text-gray-dark">
            Email instellingen
          </Subtitle>
        </div>
        <div className="space-y-4">
          {settings.map((setting, i) => (
            <SettingItem key={i} {...setting} user_id={user_id} />
          ))}
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center mb-8">
          <MdSecurity size={20} className="mr-4 text-primary" />
          <Subtitle className="text-base text-gray-dark">
            Twee-factor authenticatie
          </Subtitle>
        </div>
        <div className="rounded-lg border border-gray-200 bg-white">
          <div className="p-4">
            <div className="flex justify-between items-center">
              <div>
                <Subtitle className="text-base text-gray-dark">
                  Twee-factor authenticatie
                </Subtitle>
                <p className="text-sm text-gray-light">
                  {two_factor_enabled
                    ? `2FA is enabled (${
                        two_factor_type === "email"
                          ? "E-mail"
                          : "Authenticator-app"
                      })`
                    : "Schakel 2FA in voor extra beveiliging"}
                </p>
              </div>
              <Switch check={two_factor_enabled} onChange={handle2FAToggle} />
            </div>
          </div>
        </div>
      </div>

      <TwoFactorModal
        isOpen={showTwoFactorModal}
        onClose={() => setShowTwoFactorModal(false)}
        onSelectMethod={handleMethodSelect}
      />

      <DisableTwoFactorModal
        isOpen={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        onConfirm={handleDisable2FA}
      />

      <QRCodeModal
        isOpen={showQRCode}
        onClose={() => setShowQRCode(false)}
        qrCode={qrCode}
        secret={secret}
        onDone={() => {
          setShowQRCode(false);
        }}
      />

      <div className="mt-8">
        <div className="flex items-center mb-8">
          <LiaIndustrySolid size={20} className="mr-4 text-primary" />
          <Subtitle className="text-base text-gray-dark">Wijzigen</Subtitle>
        </div>
        {data.map((item, i) => (
          <div
            key={i}
            className="mb-4 rounded-lg border border-gray-200 bg-white"
          >
            <div className="p-4">
              <div
                onClick={() => toggle(i)}
                className="flex justify-between items-center cursor-pointer"
              >
                <Subtitle className="text-base text-gray-dark">
                  {item[0].label}
                </Subtitle>
                {selected === i ? (
                  <ChevronUp className="h-4 w-4 text-gray-light" />
                ) : (
                  <ChevronDown className="h-4 w-4 text-gray-light" />
                )}
              </div>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  selected === i ? "max-h-[500px]" : "max-h-0"
                }`}
              >
                <div className="pt-4">
                  <Form
                    fields={item}
                    initInput={{ [item[0].name]: "" }}
                    handleSubmit={handleSubmit}
                    cta="Wijzigen"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        <DeleteAccountSection />
      </div>
    </>
  );
};

function DeleteAccountSection() {
  return (
    <div className="mt-8">
      <div className="flex items-center text-red-500">
        <IoWarningOutline size={20} className="mr-4" />
        <Subtitle className="text-base text-red-500">
          Account verwijderen
        </Subtitle>
      </div>
      <div className="mt-4 border-2 border-red-500 rounded-lg p-4 bg-red-50">
        <Subtitle className="text-base">
          Wil je het account verwijderen? Neem dan contact op via info@altum.ai
        </Subtitle>
        <button className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors">
          Account verwijderen
        </button>
      </div>
    </div>
  );
}

const data: FormField[][] = [
  [
    {
      label: "Bedrijfsnaam wijzigen",
      type: "text",
      name: "company",
    },
  ],
  [
    {
      label: "KvK nummer wijzigen",
      type: "text",
      name: "kvk",
    },
  ],
  [
    {
      label: "BTW nummer wijzigen",
      type: "text",
      name: "vat",
    },
  ],
];

export default Settings;
