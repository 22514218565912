import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import AVMContainer from "../components/AVMContainer";
import AVMForm from "./AVMForm";

const Index = () => {
  const [page, setPage] = useState(1);

  return (
    <FormProvider>
      <AVMContainer page={page}>
        <AVMForm page={page} setPage={setPage} />
      </AVMContainer>
    </FormProvider>
  );
};

export default Index;
